import React, { useEffect, useState, useContext, useCallback } from "react";

import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import Tooltip from "utils/Tooltip";
import TagList from "photos/components/TagList";
import UsernameList from "photos/components/UsernameList";
import PhotoFilter from "../components/PhotoFilter";
import PhotoList from "../components/PhotoList";

import Slider from "rc-slider";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "rc-slider/assets/index.css";
const Photos = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedPhotos, setLoadedPhotos] = useState();
  const [allMembers, setAllMembers] = useState([]);
  const [photoSize, setPhotoSize] = useState(4);
  const auth = useContext(AuthContext);
  const [render, setRender] = useState(false);
  var endDate = new Date();
  endDate.setFullYear(endDate.getFullYear() - 1);

  const InitialFilters = {
    startDate: endDate,
    tags: [],
    selectedMemberIds: [],
  };
  const [filters, setFilters] = useState(InitialFilters);

  const handleSlideChange = (value) => {
    setPhotoSize(value);
  };

  const fetchPhotos = useCallback(async () => {
    if (allMembers.length === 0) return;
    const startDateParam = moment(filters.startDate).format("YYYY-MM-DD");
    try {
      const photos = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/photos/${auth.userId}?startDate=${startDateParam}&members=${filters.selectedMemberIds}&tags=${filters.tags}`,
        "GET",
        null,
        { Authorization: "Bearer " + auth.token }
      );
      setLoadedPhotos(photos);
    } catch (err) {}
  }, [auth.token, auth.userId, sendRequest, filters, allMembers.length]);

  useEffect(() => {
    fetchPhotos();
  }, [fetchPhotos]);

  const fetchMembers = useCallback(async () => {
    try {
      const userMembers = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/users`,
        "GET",
        null,
        { Authorization: "Bearer " + auth.token }
      );

      setAllMembers(userMembers);
      if (filters.selectedMemberIds.length === 0) {
        // start by selecting all member photos
        const selectedIds = userMembers.map((member) => member._id);
        setFilters({ ...filters, selectedMemberIds: selectedIds });
      }
    } catch (err) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth.token, auth.userId, sendRequest]);

  useEffect(() => {
    fetchMembers();
    console.log("fetch all members");
  }, [fetchMembers]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <div className="sticky">
        <h1 className="pt-2 pb-2 text-center trip-title">Photo Gallery</h1>

        <span className="mb-1 photoSizeControl">
          <div className="mt-0 slider-component">
            <span
              style={{ width: 200 }}
              className="activityLog-item__label mr-2"
            >
              Set Photo Size:
            </span>
            <Tooltip
              message="Move slider or set focus and move arrow keys to change photo size."
              position="right"
            >
              <Slider
                className="slider"
                min={2}
                max={12}
                defaultValue={4}
                onChange={handleSlideChange}
              />
            </Tooltip>
          </div>
         
        </span>

        <div className="filterCriteria">
         
          <Tooltip
              message="Filter photos by date, tags or members; default: view all."
              position="right"
            >
              <span
                type="button"
                className="btn btn-primary ml-3 mb-0 p-0 pl-2 pr-2 filterCriteriaHeading"
                data-toggle="modal"
                data-target="#memberModal"
                onClick={() => setRender(true)}
              >
                Filter Criteria
              </span>
            </Tooltip>
          <span className="activityLog-item__label ml-2 mr-2">
            {" "}
            Created after:
          </span>
          {moment(filters.startDate).format("MMM Do YYYY")}
          <span className="activityLog-item__label ml-2"> Members:</span>

          <UsernameList
            users={allMembers.filter((member) =>
              filters.selectedMemberIds.includes(member._id)
            )}
          />
          <span className="activityLog-item__label ml-2"> Tags:</span>
          <TagList tags={filters.tags} />
        </div>
      </div>
      {!isLoading && (
        <PhotoList
          items={loadedPhotos}
          loggedInUser={auth.userId}
          reloadData={fetchPhotos}
          photoSize={photoSize}
        />
      )}
      <PhotoFilter
        filters={filters}
        setFilters={setFilters}
        render={render}
        members={allMembers}
      />
    </React.Fragment>
  );
};

export default Photos;
