import React, { useState, useContext } from "react";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

// custom components
import NewItemForm from './NewItemForm'
import EditItemForm from './EditItemForm'
import ItemList from './ItemList'

import './ListWrapper.css'

function ListWrapper({ list, listUpdated, setList2Edit }) {

  var items = list.listItems;
  const [previousFocusEl, setPreviousFocusEl] = useState(null);
  const [item2Edit, setItem2Edit] = useState(null);

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();


  const updateItemList = async () => {
    try {
      const body = JSON.stringify(items);
      const modBody = '{"listItems":' + body + "}"
      var result = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/list/${list._id}`,
        "PUT",
        modBody, {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      });
      console.log(result);
      setList2Edit(result);
    } catch (err) {
      console.log("Error : " + err);
    }
  };


  const addItem = (item) => {
    items = [...items, item];
    updateItemList();
  }

  const deleteItem = (id) => {
    items = items.filter(t => t._id !== id);
    updateItemList();
  }

  const toggleItemCompleted = (id) => {
    items.forEach(t => {
      if (t._id === id)
        t.completed = !t.completed
    }
    )
    updateItemList();
  }

  const updateItem = (item) => {
    items = items.map(t => (
      t._id === item._id
        ? item
        : t
    ))
    updateItemList();
    closeEditItemMode();
  }

  const closeEditItemMode = () => {
    setItem2Edit(null);
    previousFocusEl.focus();
  }

  const enterEditItemMode = (item) => {
    setItem2Edit(item);
    setPreviousFocusEl(document.activeElement);
  }

  const cancelListChanges = () => {
    setList2Edit(null);
    listUpdated();
  }
  console.log("Render ListWrapper")
  return (
    <div className="container">
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <header>
        <h1 className="common-header">{`${list.listName}`}</h1>
      </header>
      {(item2Edit) && (
        <EditItemForm
          listType={list.listType}
          item2Edit={item2Edit}
          updateItem={updateItem}
          closeEditItemMode={closeEditItemMode}
        />
      )}


      {(!item2Edit) && items && (
        <>
          <ItemList
            listType={list.listType}
            items={items}
            deleteItem={deleteItem}
            toggleItemCompleted={toggleItemCompleted}
            enterEditItemMode={enterEditItemMode}
          />
          <div className="listItemEditControls">
            {(!item2Edit) && (
              <NewItemForm
                listType={list.listType}
                addItem={addItem}
              />)
            }
            <button
              className="btn"
              aria-label="close"
              onClick={cancelListChanges}>CLOSE
              <i className="fa fa-close"></i>
            </button>
          </div>
        </>
      )}
    </div>
  )
}

export default ListWrapper
