import React, {Component} from 'react';

class ConfirmatinoDialog extends Component{ 
  constructor(props){
    super(props);
    this.state={test:true}
  }

onYes = () => {
    this.props.deleteIt(this.props.id);
}    

render () {

  return (
 <div>
    

    <div className="modal fade" id={this.props.modalId} tabIndex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="myModalLabel">One Last Chance to change your mind.</h5>
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            {this.props.prompt}
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-secondary" data-dismiss="modal">{this.props.noMsg}</button>
            <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={this.onYes}>{this.props.yesMsg}</button>
          </div>
        </div>
      </div>
    </div>
    </div>

);
}
}
export default ConfirmatinoDialog;