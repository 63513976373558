import React, { useContext } from "react";

import Calendar from "homepage/components/Calendar";
import moment from "moment";
import { CalendarInfoContext } from "shared/context/calendarInfo-context";

import ErrorModal from "shared/components/UIElements/ErrorModal";

const style = {
  position: "relative",
  margin: "10px auto",
  height: "500px",
};

const CalendarContainer = (props) => {
  const calendarInfo = useContext(CalendarInfoContext);

  const { isLoading, error, clearError } = calendarInfo;

  const dayClickHandler = (e, day) => {
    calendarInfo.setSelectedDay(day);
  };

  let tripList =
    calendarInfo.trips &&
    calendarInfo.trips.map((trip) => ({
      key: trip.__id,
      description: trip.description,
      departureDate: trip.departureDate,
      returningDate: trip.returningDate,
      username: trip.user.username,
      profileImageUrl: trip.user.profileImageUrl,
      isCorrectUser: props.userId === trip.user._id,
    }));
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="d-flex justify-content-center">
          <div className="spinner-border" role="status">
            {" "}
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}

      {!isLoading && (
        <Calendar
          trips={tripList}
          style={style}
          width="100%"
          onDayClick={dayClickHandler}
          selectedDay={calendarInfo.selectedDay}
          currentDateObj={
            calendarInfo.currentDateObj
              ? calendarInfo.currentDateObj
              : new moment()
          }
          setCurrentDateObj={calendarInfo.setCurrentDateObj}
        />
      )}
    </React.Fragment>
  );
};

export default CalendarContainer;
