import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Card from '../../shared/components/UIElements/Card';
import Button from '../../shared/components/FormElements/Button';
import { AuthContext } from '../../shared/context/auth-context';
import { useHttpClient } from '../../shared/hooks/http-hook';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHistory } from 'react-router-dom';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import FileUpload from '../../shared/formik/FileUpload';
import TextInput from '../../shared/formik/TextInput';
import PasswordInput from '../../shared/formik/PasswordInput';
import Select from '../../shared/formik/Select';

// And now we can use these
const UserEdit = () => {
  const history = useHistory();
  const [user, setUser] = useState(undefined);
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  
  const submitUser = async (e,values, touched, handleSubmit) => {

    handleSubmit(e);  // allow formik form processing

    try {
      const formData = new FormData();
      touched.email && formData.append('email', values.email.trim());
      touched.username && formData.append('username', values.username.trim());
      touched.password && formData.append('password', values.password);
      (touched.profileImageUrl || touched.houseImageUrl) && formData.append('folder', "Users");
      touched.profileImageUrl && formData.append('image', values.profileImageUrl);
      touched.houseImageUrl && formData.append('houseImage', values.houseImageUrl);
      touched.alert && formData.append('alert', values.alert)
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}`,
        'PUT',
        formData, {
        Authorization: 'Bearer ' + auth.token
      });

      if (responseData)
      history.goBack();

    } catch (err) {
      console.log("ERROR Updating User : " + err.message)
    }


  }


  const fetchUser = useCallback(async () => {

    try {
      let t = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}`,
        'GET',
        null,
        { Authorization: 'Bearer ' + auth.token }
      );
      setUser(t);
    }
    catch (err) {
      console.log("Error : " + err)
    }
  }, [auth.token, auth.userId, sendRequest])

  useEffect(() => {

    fetchUser();

  }, [fetchUser]);

  if (isLoading) return <LoadingSpinner asOverlay />
  if(!user)
    return null;
  return (
    <>
       <ErrorModal error={error} onClear={clearError} />
      <Formik
        enableReinitialize
        initialValues={{
          profileImageUrl: user.profileImageUrl,
          houseImageUrl: user.houseImageUrl,
          username: user.username,
          password: user.password,
          email: user.email,
          alert: user.alert || 'Never', // added for our select
        }}
        validationSchema={Yup.object({
          profileImageUrl: Yup.mixed()
            .required('Please select an image / selfie!'),
          houseImageUrl: Yup.mixed()
            .required('Please select an image of your house!'),
          username: Yup.string()
            .required('Required'),
          password: Yup.string()
            .required('Required')
            .min(6,'Please enter a valid password, at least 6 characters.'),
          email: Yup.string()
            .email('Invalid email address')
            .required('Required'),
          alert: Yup
          .string()
          .oneOf(['Never', 'Daily', 'Weekly', 'Monthly'])
        })}
        
      >
         {({values, errors, touched, isValid, handleReset, handleSubmit, dirty}) => (
          <Card className="authentication">
            <Form>
              <Field
                label="Photo"
                name="profileImageUrl"
                id="profileImageUrl"
                center="true"
                component={FileUpload}
              />
              <ErrorMessage name="profileImageUrl" component="div" />
              <Field
                label="House"
                name="houseImageUrl"
                id="houseImageUrl"
                center="true"
                component={FileUpload}
              />
              <ErrorMessage name="houseImageUrl" component="div" />
              <TextInput
                label="Name"
                name="username"
                type="text"
              />

              <PasswordInput
                label="Password"
                name="password"
              />
              <TextInput
                label="Email Address"
                name="email"
                type="email"
                placeholder="jane@formik.com"
              />
              <Field label="Send Email Alert when someone creates or updates a trip" name="alert" component={Select}>

                <option value="Never">Never</option>
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
              </Field>
                <div>
                <Button type="submit" disabled={!dirty} onClick={(e) => submitUser(e,values, touched, handleSubmit)}>Submit</Button>
                <Button type="button" disabled={!dirty}  onClick={handleReset}>Reset</Button>
                <Button type="button"  onClick={() => history.goBack()}>Cancel</Button>
                </div>
            </Form>
          </Card>
        )}
      </Formik>
    </>
  );
};

export default UserEdit;