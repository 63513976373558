import React from 'react';
import moment from 'moment'

import Photo from '../../homepage/components/Photo/Photo'
import './PhotoList.css';

const PhotoList = props => {

  
  if (!props.items || props.items.length === 0)
    return (<p className='text-center'>No photos found for that search criteria!</p>)


  props.items.sort((photo1, photo2) => {
    if (photo1.trip._id > photo2.trip._id)
      return -1;
    if (photo1.trip._id < photo2.trip._id)
      return 1;
    if (photo1.trip.history)
      return 1;
    if (photo1.trip.user.username > photo2.trip.user.username)
      return -1;
    if (photo1.trip.user.username < photo2.trip.user.username)
      return 1;
    return 0;  
  });
  let tripHeading;
  let tripHeadingDisplayed = false;
  let currentTrip ;
  const colSize = "col-12 col-sm-6 col-md-3 col-lg-" + props.photoSize;
  return (
    <div className="container-fluid">
      <div className="row">
        {props.items.map(
          (photo, index) => {
            
            tripHeadingDisplayed = true;
            if (photo.trip._id !== currentTrip) {
              
              const departureDate = moment(photo.trip.departureDate).format('MMM Do YYYY');
              currentTrip = photo.trip._id;

              if (photo.trip.history){
                tripHeading = <h3 className="tripHeading col-12">
                <span className="mr-5">{photo.trip.description}</span>
                </h3>
              } else {
              tripHeading = 
              <h3 className="tripHeading col-12">
                

                <span className="mr-5">Trip Description: {photo.trip.description}</span>
                <span>{!photo.trip.history && departureDate}</span> 
                <span>Member: {!photo.trip.history && photo.trip.user.username}</span> 
                </h3>
              }
              tripHeadingDisplayed = false;

            }
            return (
              <React.Fragment key={index} >
                {!tripHeadingDisplayed && tripHeading}
                <div className={colSize} >
                  <Photo photoData={photo} owner={false} reloadData={props.reloadData} />
                </div>
                </React.Fragment>
                )
              
          })
        }
      </div>
    </div>






  );
};

export default PhotoList;
