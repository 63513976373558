import React, { useContext } from "react";
import Button from "shared/components/FormElements/Button";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import FileUpload from "shared/formik/FileUpload";
import TextInput from "shared/formik/TextInput";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const GuestForm = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const postNewGuest = async (values) => {
    try {
      const formData = new FormData();
      formData.append("email", values.email.trim());
      formData.append("username", values.username.trim());
      formData.append("folder", "Guests");
      formData.append("image", values.profileImageUrl);
      formData.append("memberName", auth.username);

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/guests`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );

      props.postNewGuest(responseData);
    } catch (err) {
      console.log("Error : " + err);
    }
  };

  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/gif",
    "image/png",
  ];

  return (
    <div>
      {isLoading && <LoadingSpinner asOverlay />}
      <ErrorModal error={error} onClear={clearError} />

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal__header">
              <h1 className="modal-title" id="exampleModalLabel">
                Create New Guest
              </h1>
            </div>
            <div className="modal-body">
              <Formik
                initialValues={{
                  username: "",
                  email: "",
                  profileImageUrl: null,
                }}
                validationSchema={Yup.object().shape({
                  username: Yup.string().required(
                    "Please enter the name of the Guest."
                  ),
                  email: Yup.string()
                    .required("Please enter the email address of the Guest.")
                    .email("Invalid email address"),
                  profileImageUrl: Yup.mixed()
                    .required("Please provide a photo of the Guest.")
                    .test(
                      "fileFormat",
                      "Unsupported Format",
                      (value) => value && SUPPORTED_FORMATS.includes(value.type)
                    ),
                })}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                  postNewGuest(values);
                  setSubmitting(false);
                  resetForm({});
                }}
              >
                {({
                  isValid,
                  touched,
                  handleReset,
                  handleSubmit,
                  values,
                  dirty,
                }) => (
                  <Form>
                    <div className="form-group">
                      <Field
                        label="Photo of Guest:"
                        name="profileImageUrl"
                        id="profileImageUrl"
                        component={FileUpload}
                      />
                      <ErrorMessage name="profileImageUrl" component="div" />

                      <TextInput
                        label="Name: "
                        name="username"
                        type="text"
                        placeholder="Name of the Guest"
                      />

                      <TextInput
                        label="Email Address"
                        name="email"
                        type="email"
                        placeholder="jane@campo.com"
                      />
                    </div>
                    <div>
                      <Button
                        dataDismiss="modal"
                        onClick={handleSubmit}
                        type="submit"
                        disabled={!(dirty && isValid)}
                      >
                        Submit
                      </Button>
                      <Button dataDismiss="modal" onClick={handleReset}>
                        Close
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GuestForm;
