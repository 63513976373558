import React, { useContext } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Card from "../../shared/components/UIElements/Card";
import Button from "../../shared/components/FormElements/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import { AuthContext } from "../../shared/context/auth-context";
import TextInput from "../../shared/formik/TextInput";
import PasswordInput from "../../shared/formik/PasswordInput";
import Login from "../components/Login";
// And now we can use these
const ResetPassword = (props) => {
  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const submitUser = async (values) => {
    try {
      const userParams = {
        resetPassLink: props.match.params.token,
        password: values.password,
      };

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/resetpass`,
        "PUT",
        JSON.stringify(userParams),
        { "Content-Type": "application/json" }
      );
      if (responseData) {
        const user = JSON.stringify({
          username: props.match.params.name,
          password: values.password,
        });
        Login(sendRequest, user, auth);
      }
      //history.push('/signin')
    } catch (err) {
      console.log("ERROR Updating User : " + err.message);
    }
  };

  if (isLoading) return <LoadingSpinner asOverlay />;

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <Formik
        initialValues={{
          username: props.match.params.name,
          password: "",
          email: props.match.params.email,
        }}
        validationSchema={Yup.object({
          username: Yup.string().required("Required"),
          password: Yup.string()
            .required("Required")
            .min(6, "Please enter a valid password, at least 6 characters."),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
        })}
        onSubmit={(values, actions) => {
          submitUser(values);
        }}
      >
        {({ values, dirty, isValid }) => (
          <Card className="authentication">
            <Form>
              <h1 className="mx-auto common-header">Create New Password</h1>
              <PasswordInput
                label="Password"
                name="password"
              />
              <TextInput
                label="Email Address"
                name="email"
                type="email"
                readOnly={true}
              />
              <TextInput
                label="Name"
                name="username"
                type="text"
                readOnly={true}
              />

              <div>
                <Button type="submit" disabled={!(dirty && isValid)}>
                  Submit
                </Button>
              </div>
            </Form>
          </Card>
        )}
      </Formik>
    </>
  );
};

export default ResetPassword;
