import React, { useEffect } from 'react';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import styles from './Item.module.css';
// library imports

const EditItemForm = ({ listType, item2Edit, updateItem, closeEditItemMode }) => {

  useEffect(() => {
    const closeModalIfEscaped = (e) => {
      e.key === "Escape" && closeEditItemMode();
    }

    window.addEventListener('keydown', closeModalIfEscaped)

    return () => {
      window.removeEventListener('keydown', closeModalIfEscaped)
    }
  }, [closeEditItemMode])

  const handleFormSubmit = (values) => {
    updateItem({
      _id: item2Edit._id,
      name: values.name,
      note: values.note,
      quantity: values.qty
    })
  }
  const getInitialValues = () => {
    return listType !== 'ToDo' ? { name: item2Edit.name, note: item2Edit.note, qty: item2Edit.quantity } : { name: item2Edit.name, note: item2Edit.note }
  }
  const getClassName = () => {
    return listType === 'ToDo' ? styles.todoWrapper : styles.shoppingWrapper
  }
  return (

    <Formik
      enableReinitialize
      initialValues={getInitialValues()}
      onSubmit={handleFormSubmit}
      validationSchema={yup.object().shape({
        name: yup
          .string()
          .required("Name is required."),
      })}
    >
      {({ errors, touched, isValid, dirty, values, ...formik }) => (
        <div>

          <Form className={`${styles.wrapper} ${getClassName()}`} >
            <div className={`${styles.itemFormGroup} ${styles.listName}`}  >
              <label
                htmlFor="name"
                className="label"
              >Name: </label>
              <Field
                type="text"
                name="name"
                className="input"
                autoFocus
                placeholder="Enter item name here"
              />
            </div>
            {listType !== 'ToDo' &&
              <div className={`${styles.itemFormGroup} ${styles.listQty}`}  >
                <label
                  htmlFor="qty"
                  className="label"
                >Qty:</label>
                <Field
                  type="text"
                  name="qty"
                  className="input"
                  placeholder="Quantity"
                />
              </div>
            }
            <div className={`${styles["itemFormGroup"]} ${styles.listNote}`}  >
              <label
                htmlFor="note"
                className="label"
              >Note: </label>
              <Field
                type="textarea"
                name="note"
                className="input"
                placeholder="Enter a note or comment"
              />
            </div>
            <div className={`listItemEditControls ${styles.listAdd}`}>
              <button
                className="btn"
                aria-label="Add"
                type="submit"
                disabled={!(dirty && isValid)}
              >
                <i className="fas fa-solid fa-save"> Save</i>
              </button>
              <button
                className="btn"
                aria-label="Cancel"
                onClick={() => closeEditItemMode()}>
                Cancel
              </button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  )
}
export default EditItemForm