// component import

import React from 'react';
import Item from './Item';

// styles
import styles from './ItemList.module.css';

const ItemList = ({ listType, items, deleteItem, toggleItemCompleted, enterEditItemMode }) => {

  const getClassName = () => {
    const className = listType === "ToDo" ? styles.todoGrid : styles.shoppingGrid;
    const style = styles.listItems + " " + className;
    console.log(style);
    return style;
  }


  return (

    <>
      <ul className={getClassName()}>
        {items.sort((a, b) => a.completed - b.completed).map(item => (
          <Item
            key={item._id}
            listType={listType}
            item={item}
            deleteItem={deleteItem}
            toggleItemCompleted={toggleItemCompleted}
            enterEditItemMode={enterEditItemMode}
          />
        ))
        }
      </ul>



    </>

  )
}
export default ItemList