import React, { useContext, Fragment } from "react";
import ConfirmatinoDialog from 'utils/Confirmation';
import { AuthContext } from 'shared/context/auth-context';
import { useHttpClient } from 'shared/hooks/http-hook';
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";


import NewListModalForm from "./NewListModalForm";
// styles
import styles from './ListCollection.module.css';

const ListCollection = ({ toDoLists, shoppingLists, listUpdated, setList2Edit }) => {

    const auth = useContext(AuthContext);
    const { isLoading, error, sendRequest, clearError } = useHttpClient();

    const numItemsStr = (campList) => {
        const item = campList.listItems.length === 1 ? 'Item' : 'Items';
        return campList.listItems && `${campList.listItems.length} ` + item;
    }

    const deleteList = async (id) => { // eslint-disable-next-line no-unused-vars
        const responseData = await sendRequest(`${process.env.REACT_APP_BACKEND_URL
            }/user/${auth.userId
            }/list/${id}`, 'DELETE', null, {
            Authorization: 'Bearer ' + auth.token
        });
        listUpdated();
    }
    console.log("Render ListCollection")
    return (
        <div>
            <ErrorModal error={error} onClear={clearError} />
            {isLoading && <LoadingSpinner asOverlay />}
            <div className={styles.reminderCollection}>
                <div className={styles.reminderLists}>
                    <header>
                        <h2 className="common-header">ToDo Lists</h2>
                    </header>
                    <div className={styles.reminderItems}>
                        {toDoLists && toDoLists.map((campList) => (

                            <Fragment key={campList._id} >
                                <div className={styles.reminderItem}>
                                    {campList.listName}
                                </div>
                                <div className={styles.reminderItem}>
                                    {numItemsStr(campList)}
                                </div>
                                <div className={styles.reminderItem}>
                                    <div className="fas fa-edit btn" onClick={() => setList2Edit(campList)}></div>
                                </div>
                                <div className={styles.reminderItem}>
                                    <ConfirmatinoDialog deleteIt={deleteList}
                                        id={campList._id}
                                        modalId={"listModal" + campList._id}
                                        noMsg={"Not sure, Close without Deleting."}
                                        yesMsg={"Yes, Delete this List"}
                                        prompt={"Are you sure?"} />
                                    <div className="fas fa-trash btn" data-toggle="modal" data-target={"#listModal" + campList._id}></div>
                                </div>
                            </Fragment>
                        ))}
                    </div>
                </div>
                <div className={styles.reminderLists}>
                    <header>
                        <h2 className="common-header">Shopping Lists</h2>
                    </header>
                    <ul className={styles.reminderItems}>
                        {shoppingLists && shoppingLists.map((campList) => (
                            <Fragment key={campList._id} >
                                <div className={styles.reminderItem}>
                                    {campList.listName}
                                </div>
                                <div className={styles.reminderItem}>
                                    {numItemsStr(campList)}
                                </div>
                                <div key={campList._id} className={styles.reminderItem}>
                                    <div className="fas fa-edit btn" onClick={() => setList2Edit(campList)}></div>
                                </div>
                                <div className={styles.reminderItem}>
                                    <ConfirmatinoDialog deleteIt={deleteList}
                                        id={campList._id}
                                        modalId={"listModal" + campList._id}
                                        noMsg={"Not sure, Close without Deleting."}
                                        yesMsg={"Yes, Delete this List"}
                                        prompt={"Are you sure?"} />
                                    <div className="fas fa-trash btn" data-toggle="modal" data-target={"#listModal" + campList._id}></div>
                                </div>
                            </Fragment>
                        ))}
                    </ul>
                </div>
            </div>
            <NewListModalForm setList2Edit={setList2Edit} listUpdated={listUpdated} />
        </div>

    );
};

export default ListCollection;
