const Login = async (sendRequest, user, auth) => {
  try {
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/auth/signin`,
      "POST",
      user,
      {
        "Content-Type": "application/json",
      }
    );
    if (responseData) {
      auth.login(
        responseData.id,
        responseData.token,
        responseData.username,
        responseData.profileImageUrl,
        responseData.guestOfMember
      );
    }
  } catch (err) {
    console.log("Error logging in: " + err.message);
  }
};

export default Login;
