import React, { useState } from "react";
import "./Tooltip.css";

const Tooltip = (props) => {
  const [displayTooltip, setDisplayTooltip] = useState(false);

  let message = props.message;
  let position = props.position;
  return (
    <span
      className="my-tooltip"
      onMouseLeave={() => setDisplayTooltip(false)}
      onBlur={() => setDisplayTooltip(false)}
    >
      {displayTooltip && (
        <div className={`my-tooltip-bubble my-tooltip-${position}`}>
          <div className="my-tooltip-message">{message}</div>
        </div>
      )}
      <span
        className="my-tooltip-trigger"
        onMouseOver={() => setDisplayTooltip(true)}
      >
        {props.children}
      </span>
    </span>
  );
};

export default Tooltip;
