import React, { useContext } from "react";
import TripItem from "./TripItem";
import moment from "moment";
import { tripsOnThisDay } from '../../../utils/tripUtils';
import { CalendarInfoContext } from '../../../shared/context/calendarInfo-context';

const TripList = () => {
  const calendarInfo = useContext(CalendarInfoContext);
  const tripsOnDay = tripsOnThisDay(calendarInfo.trips, calendarInfo.selectedDay);
  const selectedDayFormatted = moment(calendarInfo.selectedDay).format('MM-DD-YYYY');

  let tripList = tripsOnDay && tripsOnDay.map((trip) => {
    return <TripItem
      key={trip._id}
      trip={trip}
    />
  });
  const currentDayMessage = <h1 className="common-header m-0 p-1">Who's there on {selectedDayFormatted}</h1>
    
  return (
    <div>
      {currentDayMessage}
      <div className="d-flex flex-wrap flex-row bd-highlight mb-3">
        {tripList}
      </div>
    </div>
  )
}


export default TripList;
