import React, { useContext, useEffect } from "react";
import CalendarContainer from "homepage/components/Calendar/CalendarContainer";
import TripList from "homepage/components/Trip/TripList";

import { CalendarInfoContext } from "shared/context/calendarInfo-context";
import { AuthContext } from "shared/context/auth-context";

const Homepage = () => {
  const auth = useContext(AuthContext);
  const calendarInfo = useContext(CalendarInfoContext);
  const { fetchTrips } = calendarInfo;

  useEffect(() => {
    if (auth.userId) {
      fetchTrips(auth.userId, auth.token);
    }
  }, [auth.userId, auth.token, fetchTrips]);

  if (!auth.token) {
    return (
      <div className="home-hero">
        <img className="coronaSign" src="corona-sign.jpg" alt="" />
        <iframe
          className="weather"
          src="https://www.weatherlink.com/embeddablePage/show/59376af68c1b4f2598c0734fa413ecbf/slim"
          width="345"
          height="550"
          frameBorder="1"
          title="weather"
        ></iframe>
        {/* <iframe src='https://www.weatherlink.com/embeddablePage/show/59376af68c1b4f2598c0734fa413ecbf/wide' width='560' height='480' frameborder='0'></iframe>
        <iframe src='https://www.weatherlink.com/embeddablePage/show/59376af68c1b4f2598c0734fa413ecbf/slim' width='338' height='500' frameborder='0'></iframe>
         <iframe src='https://www.weatherlink.com/embeddablePage/show/59376af68c1b4f2598c0734fa413ecbf/signature' width='760' height='200' frameborder='0'></iframe>
          */}
      </div>
    );
  }

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-xl-6 pl-4">
          <CalendarContainer userId={auth.userId} className="homepage-center" />
        </div>
        <div className="col-xl-6 pl-4">
          <TripList />
        </div>
      </div>
    </div>
  );
};

export default Homepage;
