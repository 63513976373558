import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Navigationbar from "Navigationbar";
import Main from "Main";
import ErrorBoundry from "utils/ErrorBoundry";
import { AuthContext } from "shared/context/auth-context";
import { useAuth } from "shared/hooks/auth-hook";
import { useTripsData } from "shared/hooks/trips-hook";
import { CalendarInfoContext } from "shared/context/calendarInfo-context";
import { useCalendarData } from "shared/hooks/calendar-hook";

const App = () => {
  const {
    token,
    login,
    logout,
    userId,
    username,
    profileImageUrl,
    guestOfMember,
  } = useAuth();
  const { trips, fetchTrips, isLoading, error, clearError } = useTripsData();
  const {
    selectedDay,
    setSelectedDay,
    currentDateObj,
    setCurrentDateObj,
  } = useCalendarData();

  // fetchTrips();
  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        username: username,
        profileImageUrl: profileImageUrl,
        guestOfMember: guestOfMember,
        login: login,
        logout: logout,
      }}
    >
      <CalendarInfoContext.Provider
        value={{
          trips: trips,
          selectedDay: selectedDay,
          fetchTrips: fetchTrips,
          isLoading: isLoading,
          error: error,
          clearError,
          setSelectedDay,
          currentDateObj,
          setCurrentDateObj,
        }}
      >
        <ErrorBoundry>
          <Router>
            <div className="onboarding">
              <Navigationbar />
              <Main token={token} />
            </div>
          </Router>
        </ErrorBoundry>
      </CalendarInfoContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
