import React from "react";
const Select = ({children, field, form, label}) => {
 
  return (
    <>
      <div className={`form-control`}>
        <label htmlFor={field.name}>{label}</label>
        <select {...field} >
          {children}
        </select>
      </div>
    </>
  );
};

export default Select;
