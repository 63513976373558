import React, { useContext, useState, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Modal from "shared/components/UIElements/Modal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import styles from "./ListCollection.module.css";

const NewListModalForm = ({ setList2Edit, listUpdated }) => {
  const [showModal, setShowModal] = useState(false);

  const displayModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const hideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const submitHandler = async (values, { setSubmitting }) => {
    try {
      postNewList(values);
      setSubmitting(false);
      hideModal();
    } catch (err) {}
  };

  const auth = useContext(AuthContext);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const postNewList = async (values) => {
    try {
      const formData = new FormData();
      formData.append("listName", values.listName.trim());
      formData.append("listType", values.listType.trim());

      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/list`,
        "POST",
        formData,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      setList2Edit(responseData); // go into edit mode on the new list.
      listUpdated();
    } catch (err) {
      console.log("Error : " + err);
    }
  };
  console.log("Render NewListModalForm");
  return (
    <React.Fragment>
      <div className={styles["centerChild"]}>
        <button type="button" className="btn" onClick={displayModal}>
          Create New List
        </button>
      </div>
      <Modal
        className={styles["modalWrapper"]}
        onCancel={hideModal}
        header="Create New List"
        headerClass="modalNewList"
        show={showModal}
      >
        <Formik
          initialValues={{ listName: "", listType: "ToDo" }}
          onSubmit={submitHandler}
          validationSchema={yup.object().shape({
            listName: yup
              .string()
              .required("Unable to Submit without List Name"),
          })}
        >
          {({ errors, touched, isValid, dirty, values, ...formik }) => (
            <div>
              {isLoading && <LoadingSpinner asOverlay />}
              <ErrorModal error={error} onClear={clearError} />

              <Form>
                <div className={styles["newListWrapper"]}>
                  <div className={styles["list-group"]}>
                    <label htmlFor="name" className="label">
                      Name:
                    </label>
                    <Field
                      type="text"
                      id="name"
                      name="listName"
                      className="input"
                      autoFocus
                      placeholder="Enter List name"
                    />
                  </div>
                  <div className={styles["list-group"]}>
                    <label htmlFor="listType" className="label">
                      List Type:
                    </label>
                    <div
                      role="group"
                      aria-labelledby="listType"
                      className={styles["listType"]}
                    >
                      <label className="noWrap">
                        <Field type="radio" name="listType" value="ToDo" />
                        ToDo
                      </label>
                      <label className="noWrap">
                        <Field type="radio" name="listType" value="Shopping" />
                        Shopping
                      </label>
                    </div>
                  </div>
                </div>
                <div className="listItemEditControls">
                  <button
                    type="submit"
                    disabled={!(dirty && isValid)}
                    className="btn"
                  >
                    SUBMIT
                  </button>
                  <button type="button" className="btn" onClick={hideModal}>
                    CANCEL
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default NewListModalForm;
