import React, { useRef, useState } from 'react'
import Button from '../../shared/components/FormElements/Button';

const FileUpload = (props) => {
    const filePickerRef = useRef();
    const { field, form } = props;
    const meta = form.getFieldMeta(field.name);

    const fieldValue = form.values[field.name];
    const [previewUrl, setPreviewUrl] = useState(fieldValue);


    if (!fieldValue && previewUrl){     // handle reset
      setPreviewUrl(() => null);
    }
  
    const pickImageHandler = () => {
      filePickerRef.current.click();
    };
    const handleChange = (e) => {
      form.setFieldValue(field.name, e.currentTarget.files[0]);
      form.setFieldTouched(field.name, true, false);
      form.validateField(field.name);
      const reader = new FileReader();
  
      reader.onload = function (event) {
        setPreviewUrl(event.target.result);
      };
      reader.readAsDataURL(e.currentTarget.files[0]);
  
    };
    return (
        <div>
          <input
            key = {fieldValue}
            id={props.id}
            ref={filePickerRef}
            style={{ display: 'none' }}
            type="file"
            accept=".jpg,.png,.jpeg"
            onChange={(o) => handleChange(o)}
          />
          <div className={`form-control ${meta.touched && meta.error && 'form-control--invalid'}`}  >
            <div><b>{props.label}</b></div>
            <div className="image-upload__preview">
              {previewUrl && <img src={previewUrl} alt="Guest." />}
            </div>
            <Button type="button" onClick={pickImageHandler}>
              Pick Image
            </Button>
          </div>
        </div>
      );
    }
    export default  FileUpload;