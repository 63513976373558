import React, { useEffect, useRef, useState, useContext } from 'react';

import UsersList from '../components/UsersList';
import ErrorModal from '../../shared/components/UIElements/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/LoadingSpinner';
import { useHttpClient } from '../../shared/hooks/http-hook';
import { CalendarInfoContext } from '../../shared/context/calendarInfo-context';
import { AuthContext } from '../../shared/context/auth-context';

const Users = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedUsers, setLoadedUsers] = useState();
  const calendarInfoContext = useRef(useContext(CalendarInfoContext));
  const auth = useContext(AuthContext);
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await sendRequest( `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/users`,
        'GET', 
        null, 
        { Authorization: 'Bearer ' + auth.token }
    );

        setLoadedUsers(users);
      } catch (err) {}
    };
    fetchUsers();
  }, [sendRequest, auth.userId, auth.token]);

  useEffect(() => {
    if (calendarInfoContext.current.trips.length === 0){
      calendarInfoContext.current.fetchTrips(auth.userId, auth.token);
    }
  },[auth.userId, auth.token, calendarInfoContext])

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!isLoading && loadedUsers && <UsersList items={loadedUsers} trips={calendarInfoContext.current.trips} loggedInUser = {auth.userId} />}
    </React.Fragment>
  );
};

export default Users;
