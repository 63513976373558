import React from "react";
import moment from "moment";
import "./calendar.css";
import DayItem from "./DayItem";
import { tripsOnThisDay } from "utils/tripUtils";

export default class Calendar extends React.Component {
  state = {
    showMonthPopup: false,
    showYearPopup: false,
  };

  weekdays = moment.weekdays(); //["Sunday", "Monday", "Tuesday", "Wednessday", "Thursday", "Friday", "Saturday"]
  weekdaysShort = moment.weekdaysShort(); // ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"]
  months = moment.months();

  year = () => {
    return this.props.currentDateObj.format("Y");
  };
  month = () => {
    return this.props.currentDateObj.format("MMMM");
  };
  daysInMonth = () => {
    return this.props.currentDateObj.daysInMonth();
  };
  currentDate = () => {
    return this.props.currentDateObj.get("date");
  };
  currentDay = () => {
    return this.props.currentDateObj.format("D");
  };

  firstDayOfMonth = () => {
    let dateContext = this.props.currentDateObj;
    let firstDay = moment(dateContext).startOf("month").format("d"); // Day of week 0...1..5...6
    return firstDay;
  };

  setMonth = (month) => {
    let monthNo = this.months.indexOf(month);
    let dateContext = Object.assign({}, this.props.currentDateObj);
    dateContext = moment(dateContext).set("month", monthNo);
    this.props.setCurrentDateObj(dateContext);
  };

  nextMonth = () => {
    let dateContext = Object.assign({}, this.props.currentDateObj);
    dateContext = moment(dateContext).add(1, "month");
    this.props.setCurrentDateObj(dateContext);
    this.props.onNextMonth && this.props.onNextMonth();
  };

  prevMonth = () => {
    let dateContext = Object.assign({}, this.props.currentDateObj);
    dateContext = moment(dateContext).subtract(1, "month");
    this.props.setCurrentDateObj(dateContext);
    this.props.onPrevMonth && this.props.onPrevMonth();
  };

  onSelectChange = (e, data) => {
    this.setMonth(data);
    this.props.onMonthChange && this.props.onMonthChange();
  };
  SelectList = (props) => {
    let popup = props.data.map((data) => {
      return (
        <div key={data}>
          <div
            onClick={(e) => {
              this.onSelectChange(e, data);
            }}
          >
            {data}
          </div>
        </div>
      );
    });

    return <div className="month-popup">{popup}</div>;
  };

  onChangeMonth = (e, month) => {
    this.setState((state) => ({ showMonthPopup: !state.showMonthPopup }));
  };

  MonthNav = () => {
    return (
      <span
        className="label-month dropdown-toggle"
        onClick={(e) => {
          this.onChangeMonth(e, this.month());
        }}
       
      >
        {this.month()}
        {this.state.showMonthPopup && <this.SelectList data={this.months} />}
      </span>
    );
  };

  onDayClick = (e, day) => {
    this.props.onDayClick && this.props.onDayClick(e, day);
  };

  render() {
    // Map the weekdays i.e Sun, Mon, Tue etc as <td>
    let weekdays = this.weekdaysShort.map((day) => {
      return (
        <td key={day} className="week-day">
          {day}
        </td>
      );
    });

    let blanks = [];
    for (let i = 0; i < this.firstDayOfMonth(); i++) {
      blanks.push(
        <td key={i * 80} className="empty-slot">
          {""}
        </td>
      );
    }

    let daysInMonth = [];
    for (let d = 1; d <= this.daysInMonth(); d++) {
      const dateOfDay = moment(
        this.year() + "-" + this.month() + "-" + d,
        "YYYY-MMMM-DD"
      );
      const isToday = new moment().startOf("day").diff(dateOfDay, "days") === 0; // is this day 0 days away from today
      let className = isToday ? "day current-day" : "day";

      let sDay = new moment(this.props.selectedDay).startOf("day");
      const isSelectedDay = dateOfDay.diff(sDay, "days") === 0; // is this day 0 days from selected day

      let selectedClass = isSelectedDay ? " selected-day " : "";

      const todaysTrips = tripsOnThisDay(this.props.trips, dateOfDay);

      daysInMonth.push(
        <td
          key={d}
          className={className + selectedClass}
          onClick={(e) => {
            this.onDayClick(e, dateOfDay);
          }}
        >
          <DayItem trips={todaysTrips} day={d} />
        </td>
      );
    }

    var totalSlots = [...blanks, ...daysInMonth];
    let rows = [];
    let cells = [];

    totalSlots.forEach((row, i) => {
      if (i % 7 !== 0) {
        cells.push(row);
      } else {
        let insertRow = cells.slice();
        rows.push(insertRow);
        cells = [];
        cells.push(row);
      }
      if (i === totalSlots.length - 1) {
        let insertRow = cells.slice();
        rows.push(insertRow);
      }
    });

    let trElems = rows.map((d, i) => {
      return <tr key={i * 100}>{d}</tr>;
    });

    return (
      <div className="calendar-container" style={this.style}>
        <table className="calendar">
          <thead className="calendar-header">
            <tr>
              <td className="calendar-header" colSpan="6">
                <this.MonthNav />
                <span className="ml-3 label-year">{this.year()}</span>
              </td>
              <td colSpan="1" className="nav-month ">
                <i
                  className="prev fa fa-fw fa-chevron-left"
                  onClick={(e) => {
                    this.prevMonth();
                  }}
                ></i>
                <i
                  className="prev fa fa-fw fa-chevron-right"
                  onClick={(e) => {
                    this.nextMonth();
                  }}
                ></i>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>{weekdays}</tr>
            {trElems}
          </tbody>
        </table>
      </div>
    );
  }
}
