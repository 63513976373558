import React, { useRef, useState, useCallback } from 'react';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Modal from "shared/components/UIElements/Modal";
import styles from './Item.module.css';

// library imports


const NewItemForm = ({ listType, addItem }) => {
  const [showModal, setShowModal] = useState(false);

  const displayModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const hideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const inputRef = useRef(null);
  const MyInput = ({ field, form, ...props }) => {
    return <input ref={inputRef} {...field} {...props} />
  }


  const handleFormSubmit = (values, { resetForm }) => {
    addItem({
      name: values.name,
      note: values.note,
      quantity: values.qty,
      completed: false
    })
    resetForm();
    inputRef.current.focus();
    hideModal();
  }

  const getInitialValues = () => {
    return listType !== 'ToDo' ? { name: "", qty: "1", note: "" } : { name: "", note: "" }
  }

  const getClassName = () => {
    return listType === 'ToDo' ? styles.todoWrapper : styles.shoppingWrapper
  }


  return (
    <React.Fragment>
      <div className={styles["centerChild"]}>
        <button type="button" className="btn" onClick={displayModal}>
          Create New Item
        </button>
      </div>
      <Modal
        className={styles["modalWrapper"]}
        onCancel={hideModal}
        header="Create New Item"
        headerClass="modalNewList"
        show={showModal}
      >
        <Formik
          initialValues={getInitialValues()}
          onSubmit={handleFormSubmit}
          validationSchema={yup.object().shape({
            name: yup
              .string()
              .required("Name is required."),
          })}
        >
          {({ errors, touched, isValid, dirty, values, handleReset, ...formik }) => (
            <div>

              <Form className={`${styles.wrapper} ${getClassName()}`} >
                <div className={`${styles.itemFormGroup} ${styles.listName}`}  >
                  <label
                    htmlFor="name"
                    className="label"
                  >Name: </label>
                  <Field

                    name="name"
                    className="input"
                    autoFocus
                    placeholder="Enter item name here"
                    component={MyInput}
                  />
                </div>
                {listType !== 'ToDo' &&
                  <div className={`${styles.itemFormGroup} ${styles.listQty}`}  >
                    <label
                      htmlFor="qty"
                      className="label"
                    >Qty:</label>
                    <Field
                      type="text"
                      name="qty"
                      className="input"
                      placeholder="Quantity"
                    />
                  </div>
                }
                <div className={`${styles.itemFormGroup} ${styles.listNote}`}  >
                  <label
                    htmlFor="note"
                    className="label"
                  >Note: </label>
                  <Field
                    type="textarea"
                    name="note"
                    className="input"
                    placeholder="Enter a note or comment"
                  />
                </div>
                <div className={`listItemEditControls ${styles.listAdd}`}>
                  <button
                    className="btn"
                    aria-label="Add"
                    type="submit"
                    disabled={!(dirty && isValid)}
                  >
                    <i className="fas fa-solid fa-plus"> SAVE</i>
                  </button>
                  <button type="button" className="btn" onClick={hideModal}>
                    CANCEL
                  </button>
                  <button
                    className="btn"
                    aria-label="Reset"
                    disabled={!(dirty)}
                    onClick={handleReset}>
                    RESET
                  </button>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  )
}
export default NewItemForm