import { useState, useCallback } from 'react';
import { useHttpClient } from '../../shared/hooks/http-hook';


export const useTripsData = () => {

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [trips, setTrips] = useState([]);



  const fetchTrips =
  useCallback(async (userId, token) => {
    if (token != null){
    console.log("fetchTrips")
      try {
        let t = await sendRequest(`${process.env.REACT_APP_BACKEND_URL}/trips/${userId}`,
          'GET',
          null,
          { Authorization: 'Bearer ' + token }
        );
        setTrips(t);
      } catch (err) { console.log("ERROR fetchTrips: " + err.message); }
    }
    },[sendRequest]);



return { trips, fetchTrips, isLoading, error, clearError };
};
