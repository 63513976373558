import React, { useContext, useState, useEffect } from 'react';
import { AuthContext } from "../../shared/context/auth-context";
import UserItem from './UserItem';
import Card from '../../shared/components/UIElements/Card';
import './UsersList.css';

const UsersList = props => {
  const auth = useContext(AuthContext);
  const [showDeletedMsg, setShowDeletedMsg] = useState(false);
  const [deletedUserName, setDeletedUserName] = useState('');
  const [countdown, setCouuntdown] = useState(10);

  useEffect(() => {
    if (countdown === 0) {
      auth.logout();
    }
  }) 
  
  if (props.items.length === 0) {
    return (
      <div className="center">
        <Card>
          <h2>No users found.</h2>
        </Card>
      </div>
    );
  }
  
   

  const countDownToShutdown = () => {
    setCouuntdown((c) => c-1); 
  }

  const userDeletedHandler = (name) => {
    setDeletedUserName(name)
    setShowDeletedMsg(true);
    setInterval(countDownToShutdown, 1000);
  };


  return (

    <ul className="users-list">
      {showDeletedMsg && <div className="bigMessageContainer"> <p className="bigMessage">The user account: {deletedUserName} has been deleted.</p>
                                <p className="bigMessageCounter">Exit in {countdown} seconds.</p>

      </div>}

      {!showDeletedMsg &&
        <div>
          {props.items.map(user => (
            <UserItem
              loggedInUser={props.loggedInUser}
              key={user._id}
              user={user}
              trips={props.trips.filter(trip => trip.user._id === user._id)}
              handleDeletedUser={userDeletedHandler}

            />
          ))}
        </div>
      }
    </ul>

  );
};

export default UsersList;
