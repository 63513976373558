import React, { useContext  } from 'react';
import UserTripList from './UserTripList';
import './UserItem.css';
import { Link } from "react-router-dom";
import { AuthContext } from "../../shared/context/auth-context";
import { useHttpClient } from "../../shared/hooks/http-hook";
import ConfirmatinoDialog from "../../utils/Confirmation";

const UserItem = props => {

  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();

  
  const deleteUser = async () => {
    // eslint-disable-next-line no-unused-vars
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );
    props.handleDeletedUser(props.user.username);
    


  };

  return (

    <li className="user-item">
      <ConfirmatinoDialog
        deleteIt={deleteUser}
        modalId={"userModal" + props.user._id}
        noMsg={"No, don't remove it."}
        yesMsg={"Yes, remove it."}
        prompt={"Are you sure you want to remove the \"" + props.user.username + "\" user account?"}
      />
  
        <div>
          <div className="user-item__content">

            <img src={props.user.profileImageUrl} alt={"selfie of " + props.user.username} className="user-item__image" />
            {props.user.houseImageUrl && <img src={props.user.houseImageUrl} alt={props.user.username + "'s house"} className="user-item__image user-item__image_right" />}

            <div >
              <h2>{props.user.username}</h2>
              <h4>{props.user.email}</h4>
              <h4>{"Alerts : " + props.user.alert} </h4>
              <UserTripList trips={props.trips} />
            </div>

          </div>
          {props.loggedInUser === props.user._id &&
            <div className="user-item-edit">
              <Link className="bottom btn btn-primary m-2"
                to={{ pathname: `/users/${props.loggedInUser}/edit` }}>
                Edit
              </Link>
              <button
                className="bottom btn btn-primary m-2"
                data-toggle="modal"
                data-target={"#userModal" + props.user._id}
              >
                Delete
              </button>
            </div>
          }
        </div>
    </li>
  );
};

export default UserItem;
