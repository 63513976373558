import React, { useState, useContext } from 'react'
import CommentForm from './CommentForm'
import { useHttpClient } from '../../../shared/hooks/http-hook';
import { AuthContext } from '../../../shared/context/auth-context';

const Comment = (props) => {
   
    const [showing, setShowing] = useState(true);
    const [showMax, setShowMax] = useState(props.showMax || 2);
    const { comments = [], indent = 0 } = props;
    const repliedMsg = comments.length === 1 ? " person replied." : " people replied."
    const howManyReplied = comments.length + repliedMsg;
    const imageClass = indent === 0 ? "urlIcon largerImage" : "urlIcon"
    const { sendRequest } = useHttpClient();
    const auth = useContext(AuthContext);
    const indentionAmt = 15;

    let moreCommentMsg = "";
    const numMore = comments.length - showMax;
    if (numMore > 0){
        moreCommentMsg = numMore + (numMore === 1 ? " more comment" : " more comments")
    }
    
    const dateString = (date) => {
        const currentDate = Date.now();
        const otherDate = new Date(date);
        const diffInMilliseconds = currentDate - otherDate;
        const seconds = Math.floor(diffInMilliseconds / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days >= 1)
            return days + "d";
        if (hours >= 1)
            return hours + "h";
        if (minutes >= 1)
            return minutes + "m";
        else return "1m";
    }



    const divComments = comments.map((comment, index) => {
        if (showMax <= index){
            return null;
        }
        const toggleCommentForm = () => {
            props.setShowCommentForm(prevValue => prevValue === comment._id ? 0 : comment._id )
        }

        const likeComment = async () => {
            const alreadyLiked = comment.likes.filter(user => user._id === auth.userId);
            if (!alreadyLiked.length) {
                // eslint-disable-next-line no-unused-vars
                const responseData = await sendRequest(
                    `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/comments/${comment._id}`,
                    'PATCH',
                    null, {
                    Authorization: 'Bearer ' + auth.token
                });
                props.reloadData();
            } 
        }
    
        const style = { marginLeft: indent + "px" } 
    
        const likeList = comment && comment.likes && comment.likes.map((user, index) => { return <p  key={index} >{user.username}</p> })
        console.log("indent : " + indent)
        return (showing ?
            
            <div key={index}>
                <div className="singleComment" style={style}>
                    <span >
                        <img className={imageClass} src={comment.user.profileImageUrl} alt={comment.user.username} />
                    </span>
                    <span >
                        <span className="createdBy" >{comment.user.username} </span>
                        <span>{comment.text}</span>
                    </span>
                </div>
                <div style={style} >
                    { indent < 5*indentionAmt &&<span className="btn reply link" onClick={() => toggleCommentForm()}> Reply</span>}
                    <span> </span>
                    <span className="btn reply link" onClick={() => likeComment() }> Like</span>
                    <span> </span>
                    <span className="reply"> {dateString(comment.createdAt)}</span>
                    <span className="dropdown">
                        <span className="btn far fa-thumbs-up dropbtn"> {comment.likes && comment.likes.length}</span>
                        <span className="dropdown-content">
                            {likeList}
                        </span>
                    </span>
                </div>
                {props.commentFormOpenFor === comment._id &&
                <CommentForm comment={comment} indent={indent} photoId={comment.photo} prompt="Write a Reply" reloadData={props.reloadData} setShowCommentForm = {props.setShowCommentForm} />}
                <Comment indent={indent + indentionAmt } comments={comment.commentReply} reloadData={props.reloadData} setShowCommentForm = {props.setShowCommentForm} commentFormOpenFor={props.commentFormOpenFor}/>
            </div>
            :
            <div key={index}> {index === comments.length - 1 && <div className="btn link" onClick={() => setShowing(prevShowing => !prevShowing)}>{howManyReplied}</div>}</div>
        )
    })
    return (
        <div id={indent === 0 ? "commentDiv" : ""}>
            {divComments}
            {moreCommentMsg && <div className="btn link" onClick={() => setShowMax(comments.length)}>{moreCommentMsg}</div>}

        </div>)
}


export default Comment
