import React, { useContext } from 'react'

import moment from "moment";
import { Link } from "react-router-dom";
import "./Trip.css"

import DefaultProfileImg from "images/default-profile-image.jpg";
import ConfirmatinoDialog from 'utils/Confirmation';
import { AuthContext } from 'shared/context/auth-context';
import { useHttpClient } from 'shared/hooks/http-hook';
import { CalendarInfoContext } from 'shared/context/calendarInfo-context';
const TripItem = (props)=> {
  const auth = useContext(AuthContext);
  const calendarInfoContext = useContext(CalendarInfoContext);
  const { sendRequest } = useHttpClient();


  const deleteTrip = async () => {
    // eslint-disable-next-line no-unused-vars
    const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/trip/${props.trip._id}`,
        'DELETE',
        null, {
        Authorization: 'Bearer ' + auth.token
    });

        calendarInfoContext.fetchTrips(auth.userId, auth.token);
}


    const { showDetails = true } = props;
    const trip = props.trip;
    const owner = auth.userId === trip.user._id;

    const departureDate = new moment(trip.departureDate);
    const returningDate = new moment(trip.returningDate);
    const duration = returningDate.diff(departureDate, 'days') + 1;
    const durationText = duration === 1 ? `${duration} day` : `${duration} days`
     
   
    return (

      <div className="container-fluid tripItemDetails text-black" >
        <ConfirmatinoDialog deleteIt={deleteTrip}
                            modalId = {"tripModal" + props.trip._id}
                            noMsg = {"Never mind, keep the trip."}
                            yesMsg = {"Yes, I want to delete it anyway."} 
                            prompt = {"Are you sure?  This will also delete the photos."}/> 
        <div className="row">
        {showDetails && owner && <button className="tripDelete" data-toggle="modal" data-target={"#tripModal" + props.trip._id}>X<span className="tooltiptext">Delete Trip</span></button>}

          <div className="col-md-2">
          
            <img src={trip.user.profileImageUrl || DefaultProfileImg} alt={""} className="tripCreatorImage align-self-center p-1 " />
            <p className="font-weight-bold align-self-center ">{trip.user.username}</p>
          </div>
          <div className="col-md-10" style={{"display": "relative"}}>
          
            
            <p className="row"><span className="tripLabel col-3 col-lg-2">Description:</span ><span className="col-9 col-lg-10"> {trip.description}</span></p>
            <p className="row"><span className="tripLabel col-3 col-lg-2">Departure:</span ><span className="col-9 col-lg-10"> {departureDate.format('MM-DD-YYYY')}</span></p>
            <p className="row"><span className="tripLabel col-3 col-lg-2">Returning:</span ><span className="col-9 col-lg-10">{returningDate.format('MM-DD-YYYY')}</span></p>
            <p className="row"><span className="tripLabel col-3 col-lg-2">Duration:</span><span className="col-9 col-lg-10"> {durationText}</span></p>
          </div>
        </div>
        <div className="row">
          {trip.guests.length > 0 && (<div className="col-2"><p><strong>Guests:</strong>

          </p></div>)}
          <div className="col-10 container">
            <div className="row">
              {trip.guests && trip.guests.map(function (guest, idx) {

                return (
                  <div className="m-2" key={guest._id}>

                    <img src={guest.profileImageUrl} alt={guest.username} className="guest-image" />
                    <span> {guest.username}</span>
                  </div>)
              })}

            </div>
          </div>
        </div>
        {showDetails && <Link to={{
          pathname: `/users/${auth.userId}/${trip._id}/details`,
          state: {
            tripId:trip._id
          }
        }}>
          Details
      </Link>
        }
      </div>


    )
  }

export default TripItem;