import React from "react";
import { Field } from "formik";

export const tagArray = ["Fishing","Beach","ATV Rides","Sunset","Food","Party"];

const Checkboxes = () => {
    const tagCollection = tagArray.map(tag => {return <Checkbox name="tags" value={tag}/>});
    return tagCollection;
}

function Checkbox(props) {
    return (
      <Field name={props.name}>
        {({ field, form }) => (
          <label className="mr-2">
            <input className="mr-1"
              type="checkbox"
              {...props}
              checked={field.value.includes(props.value)}
              onChange={() => {
                if (field.value.includes(props.value)) {
                  const nextValue = field.value.filter(
                    value => value !== props.value
                  );
                  form.setFieldValue(props.name, nextValue);
                } else {
                  const nextValue = field.value.concat(props.value);
                  form.setFieldValue(props.name, nextValue);
                }
              }}
            />
            {props.value}
          </label>
        )}
      </Field>
    );
  }
const PhotoTags = () => {
    return (
        <div>
            <div>
            {Checkboxes()}
          </div>
        </div>
    )
}
export default PhotoTags