import { useState } from 'react';
import moment from 'moment'


export const useCalendarData = () => {

  const [selectedDay, setSelectedDay] = useState(new Date());
  const [currentDateObj, setCurrentDateObj] = useState(new moment());

  return { selectedDay, setSelectedDay, currentDateObj, setCurrentDateObj };
};
