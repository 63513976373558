import React, { useRef, useContext, useEffect } from "react";
import "./Comment.css";
import Input from "../../../shared/components/FormElements/Input";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import { useForm } from "../../../shared/hooks/form-hook";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import { AuthContext } from "../../../shared/context/auth-context";

const CommentForm = (props) => {
  const { indent } = props;
  const auth = useContext(AuthContext);
  const textInputRef = useRef();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [formState, inputHandler] = useForm(
    {
      comment: {
        value: "",
        isValid: true,
      },
    },
    false
  );

  useEffect(() => {
    textInputRef.current && textInputRef.current.focus();
  }, []);

  const postNewComment = async (comment) => {
    await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/comments`,
      "POST",
      JSON.stringify({ comment }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    props.reloadData();
    props.setShowCommentForm(0);
  };

  const handleNewComment = (event) => {
    if (event.charCode === 13) {
      event.preventDefault();
      if (formState.inputs.comment.value.length > 0) {
        let comment = {
          text: formState.inputs.comment.value,
          parentComment: props.comment && props.comment._id,
          photoId: props.photoId
        };

        postNewComment(comment);
      }
    }
  };

  const prompt = props.prompt ? props.prompt : "Write a comment";
  return (
    <div>
      <form>
        <div style={{ marginLeft: indent + "px" }} className="form-row">
          {isLoading && <LoadingSpinner asOverlay />}
          <ErrorModal error={error} onClear={clearError} />
          <Input
            element="input"
            id="comment"
            type="text"
            validators={[]}
            initialValid={true}
            onInput={inputHandler}
            placeholder={prompt}
            useRef={textInputRef}
            onKeyPress={handleNewComment}
          />
        </div>
      </form>
    </div>
  );
};

export default CommentForm;
