import React, { useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Card from "../../shared/components/UIElements/Card";
import Button from "../../shared/components/FormElements/Button";
import { useHttpClient } from "../../shared/hooks/http-hook";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import TextInput from "../../shared/formik/TextInput";
import { useHistory } from "react-router-dom";
// And now we can use these
const ForgotPassword = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const [emailMessage, setEmailMessage] = useState("");
  const submitUser = async (values) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const body = JSON.stringify(values);
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/forgotpass`,
        "PUT",
        body,
        { "Content-Type": "application/json" }
      );
      if (responseData) setEmailMessage(responseData.message);
    } catch (err) {
      console.log("ERROR Updating User : " + err.message);
    }
  };

  if (isLoading) return <LoadingSpinner asOverlay />;

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <Formik
        initialValues={{
          username: "",
          email: "",
        }}
        validationSchema={Yup.object({
          username: Yup.string().required("Required"),
          email: Yup.string()
            .email("Invalid email address")
            .required("Required"),
        })}
        onSubmit={(values, actions) => {
          submitUser(values);
        }}
      >
        {({ values, errors, isValid, dirty }) => (
          <Card className="authentication">
            {emailMessage && (
              <div>
                <h4>{emailMessage}</h4>

                <Button onClick={() => history.push('/')}>Okay!</Button>
              </div>
            )}
            {!emailMessage && (
              <Form>
                <h1 className="mx-auto common-header">Reset Password</h1>
                <p>Send email with link to reset password.</p>
                <TextInput
                  label="Name"
                  name="username"
                  type="text"
                  placeholder="enter the exact name used when you created the account"
                />
               

                <TextInput
                  label="Email Address"
                  name="email"
                  type="email"
                  placeholder="enter the account email"
                />
               

                <div>
                  <Button type="submit" disabled={!(dirty && isValid) }>
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Card>
        )}
      </Formik>
    </>
  );
};

export default ForgotPassword;
