import React, { useContext } from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Homepage from "homepage/pages/Homepage";
import TripForm from "homepage/components/Trip/TripForm";
import TripDetails from "homepage/components/Trip/TripDetails";
import Auth from "user/pages/Auth";
import { AuthContext } from "shared/context/auth-context";
import EditTripForm from "homepage/components/Trip/EditTripForm";
import Users from "user/pages/Users";
import Memories from "memories/pages/Memories";
import UserEdit from "user/components/UserEdit";
import ActivityLog from "activityLogs/pages/ActivityLog";

import Photos from "photos/pages/Photos";
import ForgotUsername from "user/components/ForgotUsername";
import ForgotPassword from "user/components/ForgotPassword";
import ResetPassword from "user/components/ResetPassword";
import ReminderLists from "campNotes/pages/ReminderLists";
import EmailForm from "email/EmailForm";
const Main = (props) => {
  const auth = useContext(AuthContext);

  let routes;
  if (auth.token) {
    routes = (
      <Switch>
        <Route exact path="/" render={(props) => <Homepage {...props} />} />
        <Route path="/email" component={EmailForm} />
        <Route path="/users/:id/trip/new" component={TripForm} />
        <Route path="/users/:id/trip/edit" component={EditTripForm} />
        <Route path="/users/:id/:trip/details" component={TripDetails} />
        <Route exact path="/users/:id/:edit" component={UserEdit} />
        <Route exact path="/users" component={Users} />
        <Route path="/memories" component={Memories} />
        <Route path="/activityLog" component={ActivityLog} />
        <Route path="/photos" component={Photos} />
        <Route path="/reminderLists" component={ReminderLists} />
        <Redirect to="/" />
      </Switch>
    );
  } else {
    routes = (
      <Switch>
        <Route exact path="/" render={(props) => <Homepage {...props} />} />
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Route path="/forgotUsername" component={ForgotUsername} />
        <Route
          path="/resetpass/:token/:name/:email"
          component={ResetPassword}
        />
        <Route
          exact
          path="/signin"
          render={(props) => {
            return <Auth isLogin={true} />;
          }}
        />
        <Route
          exact
          path="/signup"
          render={(props) => {
            return <Auth isLogin={false} />;
          }}
        />
        <Redirect to="/" />
      </Switch>
    );
  }

  return <div>{routes}</div>;
};

export default withRouter(Main);
