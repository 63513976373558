import { createContext } from 'react';

export const CalendarInfoContext = createContext({
  trips: null,
  selectedDay: null,
  setSelectedDay: () => {},
  fetchTrips: () => {},
  isLoading: false,
  error: '',
  clearError: ()=>{},
  currentDateObj: {},
  setCurrentDateObj: ()=>{}


});
