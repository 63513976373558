import React, { useContext, useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import DatePicker from "shared/formik/DatePicker";
import TextInput from "shared/formik/TextInput";

import moment from "moment";
import MultipleSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import "react-datepicker/dist/react-datepicker.css";
import GuestForm from "../Guests/GuestForm";
import { AuthContext } from "shared/context/auth-context";
import { useHttpClient } from "shared/hooks/http-hook";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";

import { Formik, Field, Form } from "formik";
import * as Yup from "yup";

import "./TripForm.css";
import "../../components/Trip/Trip.css";

const TripForm = (props) => {
  const selectedTrip =
    props.location.state && props.location.state.selectedTrip;

  const auth = useContext(AuthContext);

  const [selectedGuestElements, setSelectedGuestElements] = useState([]); // guests selected for this trip
  const [guestElements, setGuestElements] = useState(); // all guest connected to this user
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const history = useHistory();
  const [goBackNoRender, setGoBackNoRender] = useState(false);
  const [selectedGuestModified, SetSelectedGuestModified] = useState(false);

  const initForm = () => {
    if (selectedTrip) {
      return {
        description: selectedTrip.description,
        departureDate: new Date(selectedTrip.departureDate),
        returningDate: new Date(selectedTrip.returningDate),
        guests: [],
      };
    } else {
      return {
        departureDate: new moment().startOf("day").toDate(),
        returningDate: new moment().endOf("day").toDate(),
        description: "",
        guests: [],
      };
    }
  };

  const createGuestElement = (guest) => {
    return {
      id: guest._id,
      label: (
        <div>
          <img className="guest-image" src={guest.profileImageUrl} alt="" />
          <span> {guest.username}</span>
        </div>
      ),
    };
  };

  const createGuestElements = useCallback((guests) => {
    const guestElements = guests.map((guest) => {
      return createGuestElement(guest);
    });
    return guestElements;
  }, []);

  useEffect(() => {
    if (selectedTrip) {
      setSelectedGuestElements(createGuestElements(selectedTrip.guests));
    }
  }, [createGuestElements, selectedTrip]);

  const fetchGuests = useCallback(async () => {
    try {
      const userGuests = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/guests`,
        "GET",
        null,
        { Authorization: "Bearer " + auth.token }
      );
      setGuestElements(createGuestElements(userGuests));
    } catch (err) {}
  }, [auth.userId, auth.token, createGuestElements, sendRequest]);

  useEffect(() => {
    fetchGuests();
  }, [fetchGuests]);

  const handleNewSelectedGuest = (guest) => {
    fetchGuests();
    const guestElement = createGuestElement(guest);
    setSelectedGuestElements([...selectedGuestElements, guestElement]);
    SetSelectedGuestModified(true);
  };

  const handleSelectedChanged = (selectedItems) => {
    setSelectedGuestElements(selectedItems);
    SetSelectedGuestModified(true);
  };

  const submitTrip = async (trip, actions) => {
    try {
      if (trip.description === "") {
        const month = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];

        trip.description = `${
          month[trip.departureDate.getMonth()]
        } trip in ${trip.departureDate.getFullYear()}`;
      }
      let method = "POST";
      let url = `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/trip`;
      if (trip.id) {
        method = "PUT";
        url = `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/trip/${trip.id}`;
      }
      const body = JSON.stringify({ trip });
      var result = await sendRequest(url, method, body, {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      });

      if (result) {
        cancelForm();
      }

      actions.setSubmitting(false);
    } catch (err) {
      console.log("ERROR creating Trip : " + err.message);
    }
  };
  const cancelForm = (event) => {
    if (selectedTrip) history.goBack();
    // if edit mode go back to details
    else history.push("/"); // new trip go back to home calendar

    setGoBackNoRender(true);
  };

  const postTrip = (values, actions) => {
    const selectedIds = selectedGuestElements.map((item) => item.id);
    const trip = {};
    trip.guests = selectedIds;
    trip.departureDate = values.departureDate;
    trip.returningDate = values.returningDate;
    trip.description = values.description;
    trip.id = selectedTrip ? selectedTrip._id : null;
    submitTrip(trip, actions);
  };

  const title = selectedTrip ? (
    <h1 className="mx-auto common-header">Edit Trip</h1>
  ) : (
    <h1 className="mx-auto common-header">Create New Trip</h1>
  );

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />

      <Formik
        initialValues={initForm()}
        validationSchema={Yup.object().shape({
          departureDate: Yup.date().required(
            "Please enter the date of the first day of the trip."
          ),
          returningDate: Yup.date()
            .min(
              Yup.ref("departureDate"),
              "Returning Date must be after Departure date."
            )
            .required("Please enter the date of the last day of the trip."),
        })}
        onSubmit={(values, actions) => {
          postTrip(values, actions);
        }}
      >
        {({ isValid, dirty, touched }) => (
          <Form className="trip-form">
            {(isLoading || goBackNoRender) && <LoadingSpinner asOverlay />}
            <div className="trip">
              {title}

              <Field
                id="departureDate"
                name="departureDate"
                label="Date Leaving:"
                component={DatePicker}
              />

              <Field
                id="returningDate"
                name="returningDate"
                label="Date Returning:"
                component={DatePicker}
              />

              <TextInput
                id="description"
                name="description"
                label="Description"
                placeholder="What's special about this trip?"
              />

              <p className="font-italic mx-auto trip-guest-header">
                Select from this list of previous guest or select Create New to
                add a guest for the first time.
              </p>
              <div className="form-row">
                <div className="col-sm-2">
                  <label
                    className=" col-form-label p-2 font-weight-bold"
                    htmlFor="friends"
                  >
                    Guests:
                  </label>
                  <button
                    type="button"
                    className="btn btn-primary  m-1"
                    data-toggle="modal"
                    data-target="#exampleModal"
                  >
                    Create New Guest
                  </button>
                </div>
                <div className="col-sm-9 ">
                  <MultipleSelect
                    items={guestElements}
                    selectedItems={selectedGuestElements}
                    onChange={handleSelectedChanged}
                    loading={isLoading}
                    showSearch={false}
                  />
                </div>
              </div>
              <div className="tripEditCommands">
                <button
                  type="submit"
                  disabled={!(isValid && (dirty || selectedGuestModified))}
                  className="btn btn-primary m-2 p-2"
                >
                  Save Trip
                </button>
                <button
                  type="button"
                  onClick={cancelForm}
                  className="btn btn-primary m-2 p-2"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      <GuestForm postNewGuest={handleNewSelectedGuest} />
    </React.Fragment>
  );
};

export default TripForm;
