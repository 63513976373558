import React, { useContext, useState, useEffect, useCallback } from "react";
import TripItem from "./TripItem";
import Photo from "homepage/components/Photo/Photo";
import PhotoForm from "homepage/components/Photo/PhotoForm";
import { Link } from "react-router-dom";
import { AuthContext } from "shared/context/auth-context";
import { useHttpClient } from "shared/hooks/http-hook";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import ErrorModal from "shared/components/UIElements/ErrorModal";

import Tooltip from "utils/Tooltip";
import Slider from "rc-slider";
import "./Trip.css";
import "rc-slider/assets/index.css";

const TripDetails = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [photoSize, setPhotoSize] = useState(4);

  const [photos, setPhotos] = useState([]);
  const [selectedTrip, setSelectedTrip] = useState(undefined);

  const handleSlideChange = (value) => {
    setPhotoSize(value);
  };
  const fetchTrip = useCallback(async () => {

    try {
      let t = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/trip/${props.location.state.tripId}`,
        "GET",
        null,
        { Authorization: "Bearer " + auth.token }
      );
      setSelectedTrip(t);
      setPhotos(t.photos);
    } catch (err) {
      console.log("Error : " + err);
    }
  }, [auth.token, auth.userId, props.location.state.tripId, sendRequest]);

  const shouldUserAddPhotos = () => {
    if (auth.userId === selectedTrip.user._id) return true;
    if (!selectedTrip.guests) return false;

    let addPhotos = false;
    selectedTrip.guests.forEach((guest) => {
      if (guest._id === auth.userId) addPhotos = true;
    });
    return addPhotos;
  };

  useEffect(() => {
    fetchTrip();
  }, [fetchTrip]);

  const postNewPhoto = async (values) => {
    const formData = new FormData();
    formData.append("caption", values.caption);
    formData.append("tripId", selectedTrip._id);
    formData.append("folder", "Photos");
    formData.append("image", values.file);
    formData.append("tags", JSON.stringify(values.tags));
    const photo = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/photos`,
      "POST",
      formData,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setPhotos([...photos, photo]);
  };
  const photoClassName = "col-12 col-sm-6 col-md-3 col-lg-" + photoSize;
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      {!selectedTrip ? null : (
        <>
        <div className=" sticky">
          <div className="row">
            <div className="col-lg-2">
              <div className="row">
                {shouldUserAddPhotos() && (
                  <div className="pl-4 pb-2">
                    <PhotoForm
                      trip={selectedTrip}
                      postNewPhoto={postNewPhoto}
                    />
                  </div>
                )}
                {auth.userId === selectedTrip.user._id && (
                  <div>
                    <Link
                      className="btn btn-primary addButtons ml-4 mt-1"
                      to={{
                        pathname: `/users/${auth.userId}/trip/edit`,
                        state: { selectedTrip },
                      }}
                    >
                      Edit
                    </Link>
                  </div>
                )}
              </div>
              <div className="mt-2 slider-component">
                <div className="trip-item__label m-3">
                  Set Photo Size:
                  <Tooltip
                    message="Move slider or set focus and move arrow keys to change photo size."
                    position="right"
                  >
                    <Slider
                      className="slider"
                      min={1}
                      max={12}
                      defaultValue={photoSize}
                      onChange={handleSlideChange}
                    />
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="col-xl-10">
              <TripItem trip={selectedTrip} showDetails={false} />
            </div>
          </div>
        </div>
        <div className="container-fluid">  
          <div className="row tripPhotos">
            {photos.map((ti, index) => {
              return (
                <div key={index} className={photoClassName}>
                  <Photo
                    photoData={ti}
                    owner={ti.user._id === auth.userId}
                    reloadData={fetchTrip}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </>)}
    </React.Fragment>
  );
};

export default TripDetails;
