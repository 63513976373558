import React, { useState, useCallback } from "react";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Modal from "shared/components/UIElements/Modal";
import styles from "./ListCollection.module.css";
import DefaultProfileImg from "../images/default-profile-image.jpg";

const AddEmailAddressModalForm = ({ addSlectedUserElement }) => {
  const [showModal, setShowModal] = useState(false);

  const displayModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const hideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const submitHandler = async (values, { setSubmitting }) => {
    try {
      const user = {
        profileImageUrl: DefaultProfileImg,
        username: values.username,
        email: values.email,
        _id: values.email,
      };
      addSlectedUserElement(user);
      setSubmitting(false);
      hideModal();
    } catch (err) {}
  };

  console.log("Render AddEmailAddressModalForm");
  return (
    <React.Fragment>
      <div>
        <button type="button" className="btn" onClick={displayModal}>
          Add Non-Members to List
        </button>
      </div>
      <Modal
        className={styles["modalWrapper"]}
        onCancel={hideModal}
        header="Add Recipient"
        headerClass="modalNewList"
        show={showModal}
      >
        <Formik
          initialValues={{ username: "", email: "" }}
          onSubmit={submitHandler}
          validationSchema={yup.object().shape({
            username: yup
              .string()
              .required("Unable to Submit without User Name"),
            email: yup
              .string()
              .email("Invalid email address")
              .required("Unable to Submit without email address"),
          })}
        >
          {({ errors, touched, isValid, dirty, values, ...formik }) => (
            <div>
              <Form>
                <div className={styles["newListWrapper"]}>
                  <div className={styles["list-group"]}>
                    <label htmlFor="username" className="label">
                      Name:
                    </label>
                    <Field
                      type="text"
                      id="username"
                      name="username"
                      className="input"
                      autoFocus
                      placeholder="Enter User Name"
                    />
                  </div>
                  <div className={styles["list-group"]}>
                    <label htmlFor="email" className="label">
                      Email:
                    </label>
                    <Field
                      type="text"
                      id="email"
                      name="email"
                      className="input"
                      placeholder="Enter Email Address"
                    />
                  </div>

                  <div className="listItemEditControls">
                    <button
                      type="submit"
                      disabled={!(dirty && isValid)}
                      className="btn"
                    >
                      SUBMIT
                    </button>
                    <button type="button" className="btn" onClick={hideModal}>
                      CANCEL
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default AddEmailAddressModalForm;
