import React, { useState, useEffect, useCallback } from "react";
import Button from "shared/components/FormElements/Button";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { Formik, Form, Field, ErrorMessage } from "formik";
import MultipleSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import DatePicker from "shared/formik/DatePicker";
import PhotoTags from "photos/components/PhotoTags"
const MemberFilter = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMemberElements, setSelectedMemberElements] = useState([]); // members selected for this trip
  const [memberElements, setMemberElements] = useState([]); // all member connected to this user

  const handleSelectedChanged = (selectedItems) => {
    setSelectedMemberElements(selectedItems);
  };

  const createMemberElement = (member) => {
    return {
      id: member._id,
      label: (
        <div>
          <img className="guest-image" src={member.profileImageUrl} alt="" />
          <span> {member.username}</span>
        </div>
      ),
    };
  };

  const createMemberElements = useCallback((members) => {
    const memberElements = members.map((member) => {
      return createMemberElement(member);
    });
    return memberElements;
  }, []);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      // need to give some time to render after the modal is visible before setting the state
      // or else React doesn't think it needs to re-render.
      setMemberElements(createMemberElements(props.members));
      setSelectedMemberElements(createMemberElements(props.members));
      setIsLoading(false);
    }, 1000);
  }, [props.render, createMemberElements, props.members]);

  const submitMembers = (values) => {
    const selectedIds = selectedMemberElements.map((item) => item.id);
    const filters = {
      startDate: values.fromDate,
      tags:values.tags,
      selectedMemberIds: selectedIds
    }
    props.setFilters(filters);
  };

  return (
    <div>
      <div
        className="modal fade memberFilter"
        id="memberModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="memberFilterLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal__header">
              <h4 className="modal-title" id="memberFilterLabel">
                Photo Selection Criteria
              </h4>
            </div>
            <div className="modal-body">
              {isLoading && <LoadingSpinner asOverlay />}
              <Formik
                initialValues={{ fromDate: props.filters.startDate, tags:props.filters.tags, members:props.filters.selectedMemberIds }}
                onSubmit={(values, { setSubmitting }) => {
                  submitMembers(values);
                  setSubmitting(false);
                }}
              >
                {({ isValid, handleReset, handleSubmit, values, dirty }) => (
                  <Form>
                    <div className="filterSettingLabel">...after this date:</div>
                    <Field
                      id="fromDate"
                      name="fromDate"
                      selected={props.filters.startDate}
                      component={DatePicker}
                    />
                    <div className="filterSettingLabel mt-2">...including selected tags :</div>
                     <PhotoTags/>
                     <div className="filterSettingLabel">...from selected members:</div>
                    <div>
                      
                      <MultipleSelect
                        items={memberElements}
                        selectedItems={selectedMemberElements}
                        onChange={handleSelectedChanged}
                      />
                      <ErrorMessage name="email" component="div" />
                    </div>
                    <div>
                      <Button
                        dataDismiss="modal"
                        onClick={handleSubmit}
                        type="submit"
                        disabled={!dirty && !isValid}
                      >
                        Submit
                      </Button>
                      <Button dataDismiss="modal" onClick={handleReset}>
                        Close
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MemberFilter;
