import React from "react";
import TripForm from "./TripForm";

/* This is a hack -
when I had the edit page up and hit new trip , react would think I was on the same page and not re-initialize the form 
someday, with more experience I'll find a better solution 
*/
export default function EditTripForm(props) {
      return (<TripForm {...props} />)
}

 
