import React, { useState, useContext } from "react";
import Comment from "../Comment/Comment";
import CommentForm from "../Comment/CommentForm";
import ConfirmatinoDialog from "../../../utils/Confirmation";
import { AuthContext } from "../../../shared/context/auth-context";
import { useHttpClient } from "../../../shared/hooks/http-hook";
import LoadingSpinner from "../../../shared/components/UIElements/LoadingSpinner";
import ErrorModal from "../../../shared/components/UIElements/ErrorModal";

import "./Photo.css";

const Photo = (props) => {
  const auth = useContext(AuthContext);
  const [showComments, setShowComments] = useState(true);
  const [showCommentForm, setShowCommentForm] = useState(0);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const photoId = props.photoData._id;

  const deletePhoto = async () => {
    // eslint-disable-next-line no-unused-vars
    const responseData = await sendRequest(
      `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/photos/${photoId}`,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    props.reloadData();
  };

  const likePhoto = async () => {
    const alreadyLiked = photoData.likes.filter(
      (user) => user._id === auth.userId
    );
    if (!alreadyLiked.length) {
      // eslint-disable-next-line no-unused-vars
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/photos/${photoId}`,
        "PATCH",
        null,
        {
          Authorization: "Bearer " + auth.token,
        }
      );
      props.reloadData();
    }
  };

  const countTotalComments = (comment, total) => {
    comment.commentReply.forEach((comment) => {
      total += 1;
      total = countTotalComments(comment, total);
    });
    return total;
  };
  const totalPhotoComments = () => {
    let total = 0;
    props.photoData.comments.forEach((comment) => {
      total += 1;
      total = countTotalComments(comment, total);
    });
    return total;
  };
  const toggleCommentForm = () => {
    setShowCommentForm((prevValue) => (prevValue === photoId ? 0 : photoId));
  };
  const totalComments = totalPhotoComments();
  const photoData = props.photoData;
  const numComments =
    totalComments === 0
      ? "No Comments"
      : totalComments + (totalComments === 1 ? " Comment" : " Comments");
  const likeList =
    photoData.likes &&
    photoData.likes.map((user, index) => {
      return <p key={index}>{user.username}</p>;
    });

  const tags = photoData.tags ? photoData.tags.map((tag, index) => {
    return <span className="mr-1" key={index}>#{tag}</span>;
  }) : null;

  return (
    <div className="card border border-primary p-0 px-3 mb-2 bg-light text-black">
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <ConfirmatinoDialog
        deleteIt={deletePhoto}
        modalId={"photoModal" + photoId}
        noMsg={"Never mind, keep the photo."}
        yesMsg={"Yes, I want to delete it anyway."}
        prompt={"Are you sure?  This will also delete comments."}
      />
      {/*  only owner should be able to delete */}
      <span className="photoBy">Photo by {photoData.user.username}</span>
      {props.owner && (
        <button
          className="photoDelete"
          data-toggle="modal"
          data-target={"#photoModal" + photoId}
        >
          X<span className="tooltiptext">Delete Photo</span>
        </button>
      )}

      <img
        src={photoData.imageUrl}
        alt={"not available"}
        className="photoImage"
      />
      <div className="card-block">
        <p className="display-caption">{photoData.caption}</p>
        <div className="display-tags" >{tags}</div>
        <div>
          <span className="dropdown">
            <span className="btn far fa-thumbs-up dropbtn">
              {" "}
              {photoData.likes && photoData.likes.length}
            </span>
            <span className="dropdown-content">{likeList}</span>
          </span>
          <span
            className="btn far link"
            onClick={() => setShowComments((prevShow) => !prevShow)}
          >
            {numComments}
          </span>
        </div>
        <div className="d-flex justify-content-around" id="photoDiv">
          <i className="btn far fa-thumbs-up" onClick={likePhoto}>
            {" "}
            Like
          </i>
          <i
            className="btn far fa-comment-alt"
            onClick={() => toggleCommentForm()}
          >
            {" "}
            Comment
          </i>
        </div>
        {showComments && (
          <Comment
            comments={photoData.comments}
            showComments={true}
            reloadData={props.reloadData}
            setShowCommentForm={setShowCommentForm}
            commentFormOpenFor={showCommentForm}
          />
        )}
        {showCommentForm === photoId && (
          <CommentForm
            indent={0}
            photoId={photoData._id}
            reloadData={props.reloadData}
            setShowCommentForm={setShowCommentForm}
          />
        )}
      </div>
    </div>
  );
};

export default Photo;
