import React, { useContext } from 'react'
import { AuthContext } from '../../shared/context/auth-context';
import './UserItem.css';
import { Link } from "react-router-dom";
import moment from "moment";
const UserTripItem = props => {
  const auth = useContext(AuthContext);
  const departureDate = new moment(props.trip.departureDate);

  return (
    <li>
      
      <Link to={{
          pathname: `/users/${auth.userId}/${props.trip._id}/details`,
          state: {
            tripId:props.trip._id
          }
        }}> 
          <span > {departureDate.format('MM-DD-YYYY')}</span>
          <span> </span>
          <span>{props.trip.description}</span>
      </Link>
     
    </li>
  );
};

export default UserTripItem;
