import React, { useState } from "react";
import { useField } from "formik";

const PasswordInput = ({ label, ...props }) => {
  const [showPassword, setShowPassword] = useState(false);

  const [field, meta] = useField(props);
  const inputType = showPassword ? "text" : "password";
  return (
    <>
      <div
        className={`form-control ${
          meta.touched && meta.error && "form-control--invalid"
        }`}
      >
        <label htmlFor={props.id || props.name}>{label}</label>
        <input className="text-input" type={inputType} {...field} {...props} />
      </div>
      {meta.touched && meta.error ? <div>{meta.error}</div> : null}
      <div>
        <label className="mr-2">Show Password</label>
        <input
          id="showPassword"
          type="checkbox"
          onChange={() => setShowPassword(!showPassword)}
        />
      </div>
    </>
  );
};

export default PasswordInput;
