import React from "react";


const DayItem = (trips, day) => {
  const t = trips && trips.trips && trips.trips.map((trip, index) => {
    return <div key={index}>
      {trip.username}
    </div>
  });
  return <div className="dayHolder"> <span className="dayOfMonth">{trips.day}</span>{t} </div>;
}

export default DayItem;