import React, { useState, useContext, useEffect } from "react";

import Card from "../../shared/components/UIElements/Card";
import Input from "../../shared/components/FormElements/Input";
import ErrorModal from "../../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../../shared/components/UIElements/LoadingSpinner";
import ImageUpload from "../../shared/components/FormElements/ImageUpload";
import { useHistory } from "react-router-dom";
import {
  VALIDATOR_EMAIL,
  VALIDATOR_MINLENGTH,
  VALIDATOR_REQUIRE,
} from "../../shared/util/validators";
import { useForm } from "../../shared/hooks/form-hook";
import { useHttpClient } from "../../shared/hooks/http-hook";
import { AuthContext } from "../../shared/context/auth-context";
import { Link } from "react-router-dom";
import Login from "../components/Login";

import "./Auth.css";

const Auth = (props) => {
  const history = useHistory();
  const isLoginMode = props.isLogin;
  const auth = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(false);

  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [formState, inputHandler, setFormData] = useForm(
    isLoginMode
      ? {
          name: {
            value: "",
            isValid: false,
          },
          password: {
            value: "",
            isValid: false,
          },
        }
      : {
          name: {
            value: "",
            isValid: false,
          },
          image: {
            value: null,
            isValid: false,
          },
          houseImage: {
            value: null,
            isValid: false,
          },
          email: {
            value: "",
            isValid: false,
          },
          password: {
            value: "",
            isValid: false,
          },
        },

    false
  );

  useEffect(() => {
    console.log("Auth.useEffect")
    if (isLoginMode) {
      setFormData(
        {
          ...formState.inputs,
          email: undefined,
          image: undefined,
          houseImage: undefined,
        },
        formState.inputs.name.isValid && formState.inputs.password.isValid
      );
    } else {
      setFormData(
        {
          ...formState.inputs,
          email: {
            value: "",
            isValid: false,
          },
          image: {
            value: null,
            isValid: false,
          },
          houseImage: {
            value: null,
            isValid: false,
          },
        },
        false
      );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cancelForm = (event) => {
    history.goBack();
  };
  const authSubmitHandler = async (event) => {
    event.preventDefault();

    if (isLoginMode) {
      const user = JSON.stringify({
        username: formState.inputs.name.value,
        password: formState.inputs.password.value,
      });
      Login(sendRequest, user, auth);
    } else {
      try {
        const formData = new FormData();
        formData.append("email", formState.inputs.email.value);
        formData.append("username", formState.inputs.name.value);
        formData.append("password", formState.inputs.password.value);
        formData.append("folder", "Users");
        formData.append("image", formState.inputs.image.value);
        formData.append("houseImage", formState.inputs.houseImage.value);
        const responseData = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/auth/signup`,
          "POST",
          formData
        );

        auth.login(
          responseData.id,
          responseData.token,
          responseData.username,
          responseData.profileImageUrl,
          responseData.guestOfMember
        );
      } catch (err) {}
    }
  };
  const inputType = showPassword ? "text" : "password";
  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      <Card className="authentication">
        {isLoading && <LoadingSpinner asOverlay />}
        {!isLoginMode && (
          <h2 className="common-header">Create a New Account</h2>
        )}
        {isLoginMode && (
          <h2 className="common-header">Log into Existing Account</h2>
        )}

        <form onSubmit={authSubmitHandler}>
          <Input
            element="input"
            id="name"
            type="text"
            label="Name:"
            validators={[VALIDATOR_REQUIRE()]}
            errorText="Please enter a name."
            onInput={inputHandler}
          />
          {!isLoginMode && (
            <Input
              element="input"
              id="email"
              type="email"
              label="Email:"
              validators={[VALIDATOR_EMAIL()]}
              errorText="Please enter a valid email address."
              onInput={inputHandler}
            />
          )}
          {!isLoginMode && (
            <ImageUpload
              center
              id="image"
              onInput={inputHandler}
              errorText="Please provide a selfie."
              label="Members's Photo:"
            />
          )}
          {!isLoginMode && (
            <ImageUpload
              center
              id="houseImage"
              onInput={inputHandler}
              errorText="Please provide an image of your house."
              label="Members' House:"
            />
          )}
          <div>
            <Input
              element="input"
              id="password"
              type={inputType}
              label="Password:"
              validators={[VALIDATOR_MINLENGTH(6)]}
              errorText="Please enter a valid password, at least 6 characters."
              onInput={inputHandler}
            />
          </div>          
          <div className="center">
            <label className="mr-2">Show Password:</label>
            <input
              id="showPassword"
              type="checkbox"
              onChange={() => setShowPassword(!showPassword)}
            />
            </div>
          <div className="listItemEditControls">
          <button
            type="submit"
            className="btn btn-primary m-2 p-2"
            disabled={!formState.isValid}
          >
            {isLoginMode ? "LOGIN" : "SIGN UP"}
            <i className="fas fa-solid fa-save"></i>
          </button>
          <button onClick={cancelForm} className="btn btn-primary m-2 p-2">
            CANCEL
            <i className="fa fa-close"></i>
          </button>
          </div>


          {isLoginMode && (
            <div>
              <span>
              <Link
                className=" m-2"
                to={{
                  pathname: `/forgotPassword`,
                }}
              >
                Forgot Password
              </Link>
              </span>

              <span>
              <Link
                className=" m-2"
                to={{
                  pathname: `/forgotUsername`,
                }}
              >
                Forgot Username
              </Link>
              </span>
            </div>
          )}
        </form>
      </Card>
    </React.Fragment>
  );
};

export default Auth;
