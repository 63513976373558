import React, { useRef, useState, useCallback } from "react";
import { Formik, Field, Form, useField } from "formik";
import * as yup from "yup";
import Button from "shared/components/FormElements/Button";
import Modal from "shared/components/UIElements/Modal";
import PhotoTags from "photos/components/PhotoTags"


const PhotoForm = (props) => {
  const captionInputRef = useRef();
  const filePickerRef = useRef();
  const [showModal, setShowModal] = useState(false);

  const displayModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const hideModal = useCallback(() => {
    setShowModal(false);
  }, []);

  const photoSubmitHandler = async (values, { setSubmitting }) => {
    try {
      props.postNewPhoto(values);
      setSubmitting(false);
      hideModal();
    } catch (err) {}
  };

  const pickImageHandler = () => {
    filePickerRef.current.click();
  };

  const MyTextInput = ({ label, ...props }) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and alse replace ErrorMessage entirely.
    const [field, meta] = useField(props);
    return (
      <div className="photoCaption">
        <label htmlFor={props.id || props.name}>{label}</label>
        <input {...field} {...props} ref={captionInputRef} />
        {meta.touched && meta.error ? (
          <div className="alert alert-danger">{meta.error}</div>
        ) : null}
      </div>
    );
  };
  

  function FileUpload(props) {
    const { field, form } = props;
    const handleChange = (e) => {
      const file = e.currentTarget.files[0];
      form.setTouched({ file: true });
      form.setFieldValue(field.name, file);
      captionInputRef.current.focus();
    };
   

    return (
      <div className="imgbox">
        <Button type="button" onClick={pickImageHandler}>
          PICK IMAGE
        </Button>
        <input
          style={{ display: "none" }}
          ref={filePickerRef}
          type={"file"}
          onChange={(o) => handleChange(o)}
          className={"form-control"}
        />
        <div className="center-fit">
          {field.value && <img src={URL.createObjectURL(field.value)} alt="" />}
        </div>
      </div>
    );
  }

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn btn-primary mt-1 addButtons"
        onClick={displayModal}
      >
        Add Photo
      </button>
      <Modal
        onCancel={hideModal}
        header="Add Photo"
        show={showModal}
        className="modalPhoto"
      >
        <Formik
          initialValues={{ tags: [], caption: "", file: null }}
          onSubmit={photoSubmitHandler}
          validationSchema={yup.object().shape({
            file: yup.mixed().required("Please select an image."),
            caption: yup.string().required("Please enter a caption."),
          })}
        >
          {({ errors, touched, isValid, handleReset, dirty, ...formik }) => (
            <Form>
              <div className="form-group">
                <Field name="file" component={FileUpload} />
                {errors.file && touched.file ? (
                  <div className="alert alert-danger">{errors.file}</div>
                ) : null}
                <PhotoTags/>
                <MyTextInput
                  label="Caption: "
                  name="caption"
                  type="text"
                  placeholder=" Say something about this image."
                />
              </div>
              <div>
                <button
                  type="submit"
                  disabled={!(dirty && isValid)}
                  className="btn btn-primary m-2 p-2"
                >
                  Submit
                </button>
                <button
                  type="button"
                  disabled={!dirty}
                  className="btn btn-primary m-2 p-2"
                  onClick={handleReset}
                >
                  Reset
                </button>
                <button
                  type="button"
                  className="btn btn-primary m-2 p-2"
                  onClick={hideModal}
                >
                  Cancel
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </React.Fragment>
  );
};

export default PhotoForm;
