import React, { useContext, useRef } from "react";
import { Link } from "react-router-dom";
import DefaultProfileImg from "images/default-profile-image.jpg";
import { AuthContext } from "shared/context/auth-context";
import "./Navagationbar.css";

const Navigationbar = () => {
  const navButton = useRef(null);
  const linksContainerRef = useRef(null);

  function collapseNav() {
    navButton.current.classList.add("collapsed");
    linksContainerRef.current.classList.remove("show");
  }

  const auth = useContext(AuthContext);

  const handleLogout = (e) => {
    e.preventDefault();
    auth.logout();
  };

  const pdfFile =
    "https://campocoronabucket.s3-us-west-1.amazonaws.com/DOCS/Campo+Corona+User+Manual.pdf";
  return (
    <React.Fragment>
      <nav className="navbar navbar-expand-xl navbar-dark nav_top ">
        <button
          ref={navButton}
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#collapsibleNavbar"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          onClick={collapseNav}
          ref={linksContainerRef}
          className="collapse navbar-collapse"
          id="collapsibleNavbar"
        >
          {auth.token && (
            <div>
              <img
                src={auth.profileImageUrl || DefaultProfileImg}
                alt={auth.username}
                className="userImage rounded-circle border border-primary"
              />
              <span className="nav-user">{auth.username}</span>
            </div>
          )}
          {auth.token && (
            <div className="navbar-header">
              <Link to="/" className="far fa-calendar">
                {" "}
                Calendar
              </Link>
            </div>
          )}
          {auth.token ? (
            <ul className="nav navbar-nav navbar-right">
              {auth.username === "Rick" && (
                <li>
                  <Link to="/signup">Sign Up</Link>
                </li>
              )}
              <li>
                <a
                  href="https://sites.google.com/view/campo-corona-weather/home"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Weather
                </a>
              </li>

              {!auth.guestOfMember && (
                <li>
                  <Link to={`/users/${auth.userId}/trip/new`}>New Trip</Link>
                </li>
              )}
              <li>
                <Link to={`/email`}>Email</Link>
              </li>
              <li>
                <Link to={`/photos`}>Photo Gallery</Link>
              </li>
              <li>
                <Link to={`/activityLog`}>Activities</Link>
              </li>
              <li>
                <Link to="/memories">Vintage Photos</Link>
              </li>
              <li>
                <Link to="/users">Members</Link>
              </li>
              <li>
                <Link to="/reminderLists">Reminders</Link>
              </li>
              <li>
                <a href=" " onClick={handleLogout}>
                  Log out
                </a>
              </li>
              <li>
                <a href={pdfFile} target="_blank" rel="noreferrer noopener">
                  Help
                </a>
              </li>
            </ul>
          ) : (
            <ul className="nav navbar-nav navbar-right">
              <li>
                <a
                  href="https://sites.google.com/view/campo-corona-weather/home"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Weather
                </a>
              </li>
              <li>
                <Link to="/signin">Log In</Link>
              </li>
              <li>
                <a href={pdfFile} target="_blank" rel="noreferrer noopener">
                  Help
                </a>
              </li>
            </ul>
          )}
        </div>
      </nav>
    </React.Fragment>
  );
};

export default Navigationbar;
