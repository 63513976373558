import { useState, useCallback, useEffect } from 'react';

let logoutTimer;

export const useAuth = () => {
  const [token, setToken] = useState(false);
  const [tokenExpirationDate, setTokenExpirationDate] = useState();
  const [userId, setUserId] = useState(false);
  const [username, setUsername] = useState(''); 
  const [profileImageUrl, setProfileImageUrl] = useState('');
  const [guestOfMember, setGuestOfMember] = useState('');

  const login = useCallback((uid, token, username, profileImageUrl, guestOfMember, expirationDate) => {
  
    setToken(token);
    setUserId(uid);
    setUsername(username);
    setProfileImageUrl(profileImageUrl);
    setGuestOfMember(guestOfMember);

    const tokenExpirationDate =
      expirationDate || new Date(new Date().getTime() + 1000 * 60 * 60 * 1);
    setTokenExpirationDate(tokenExpirationDate);
    localStorage.setItem(
      'userData',
      JSON.stringify({
        userId: uid,
        token: token,
        username: username,
        profileImageUrl: profileImageUrl,
        guestOfMember: guestOfMember,
        expiration: tokenExpirationDate.toISOString()
      })
    );
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    setTokenExpirationDate(null);
    setUserId(null);
    setUsername(null);
    setProfileImageUrl(null);
    setGuestOfMember(null);
    localStorage.removeItem('userData');
  }, []);

  useEffect(() => {
    if (token && tokenExpirationDate) {
      const remainingTime = tokenExpirationDate.getTime() - new Date().getTime();
      logoutTimer = setTimeout(logout, remainingTime);
    } else {
      clearTimeout(logoutTimer);
    }
  }, [token, logout, tokenExpirationDate]);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('userData'));
    if (
      storedData &&
      storedData.token &&
      new Date(storedData.expiration) > new Date()
    ) {
      login(storedData.userId, storedData.token, storedData.username, storedData.profileImageUrl, storedData.guestOfMember, new Date(storedData.expiration));
    }
  }, [login]);

  return { token, login, logout, userId, username, profileImageUrl, guestOfMember };
};