import React from 'react';

import UserTripItem from './UserTripItem';
import './UsersList.css';

const UserTripList = props => {


  return (
    <React.Fragment>
      <div className='planned-trips'>
        {props.trips.length === 0 ?
          <h2>No trips planned.</h2> :
          <div>
            <h2>Trips</h2>
            <div className='scrollable'>
              <ul className='user-trip-list'>
                {props.trips.map(trip => (
                  <UserTripItem
                    key={trip._id}
                    trip={trip} />))}
              </ul>
            </div>
          </div>
        }
      </div>


    </React.Fragment>
  );
};

export default UserTripList;
