import React, { useEffect, useState, useContext } from "react";

import ActivityLogList from "../components/ActivityLogList";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import Tooltip from "utils/Tooltip";
import "react-datepicker/dist/react-datepicker.css";

const ActivityLog = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [loadedActivityLog, setLoadedActivityLog] = useState();
  const auth = useContext(AuthContext);

  var x = new Date();
  x.setMonth(x.getMonth() - 1);
  const [startDate, setStartDate] = useState(x);

  const handleChange = (date) => {
    setStartDate(new Date(date));
  };

  useEffect(() => {
    const fetchActivityLog = async () => {
      const startDateParam = moment(startDate).format("YYYY-MM-DD");
      try {
        const activityLogs = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/activityLogs/${auth.userId}?startDate=${startDateParam}`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setLoadedActivityLog(activityLogs);
      } catch (err) {}
    };
    fetchActivityLog();
  }, [sendRequest, auth.userId, auth.token, startDate]);

  return (
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && <LoadingSpinner asOverlay />}
      <h1 className="text-center trip-title">
        Activities since {moment(startDate).format("MMM Do YYYY")}
      </h1>
      <div className="activityHeading">
        <span className="activityLog-item__label">Date Filter: </span>
        <Tooltip
          message="Click here to select date used to filter Trip Activities; default previous month."
          position="right"
        >
          <ReactDatePicker
            className="datePicker_input"
            selected={startDate}
            onChange={handleChange}
          />
        </Tooltip>
      </div>

      {!isLoading && (
        <ActivityLogList items={loadedActivityLog} loggedInUser={auth.userId} />
      )}
    </React.Fragment>
  );
};

export default ActivityLog;
