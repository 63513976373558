    import React from 'react'
    import { useField } from 'formik';
    const TextInput = ({label, ...props}) => {
        // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
        // which we can spread on <input> and also replace ErrorMessage entirely.
        
        const [field, meta] = useField(props);
        return (
          <>
          <div className={`form-control ${meta.touched && meta.error && 'form-control--invalid'}`}  >
          <label htmlFor={props.id || props.name}>{label}</label>
          <input className="text-input" {...field} {...props} />
          </div>
          {meta.touched && meta.error ? (
            <div>{meta.error}</div>
          ) : null}
        </>
        );
      };
    
        export default  TextInput;