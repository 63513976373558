import React, { useEffect, useState, useContext, useCallback } from "react";
import { Formik, Form, useField } from "formik";
import * as yup from "yup";
import MultipleSelect from "@kenshooui/react-multi-select";
import "@kenshooui/react-multi-select/dist/style.css";
import ErrorModal from "../shared/components/UIElements/ErrorModal";
import LoadingSpinner from "../shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";
import Modal from "shared/components/UIElements/Modal";
import styles from "./Email.module.css";
import AddEmailAddressModalForm from "./AddEmailAddressModalForm";
import AlertModal from "shared/components/UIElements/AlertModal";
import { useHistory } from "react-router-dom";

/* Formik field defs */

const MyTextInput = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>{label}</label>
      <textarea className="text-area" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </>
  );
};
const Email = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const auth = useContext(AuthContext);
  const [selectedUserElements, setSelectedUserElements] = useState([]); // guests selected for this trip
  const [userElements, setUserElements] = useState(); // all guest connected tusers user
  const [showModal, setShowModal] = useState(false);
  const [showUsername, setShowUsername] = useState(false);
  const [emailSentAlert, setEmailSentAlert] = useState(false);
  const history = useHistory();
  const toggleUsername = () => {
    setShowUsername((prev) => !prev);
  };
  const displayModal = useCallback(() => {
    setShowModal(true);
  }, []);

  const hideModal = useCallback(() => {
    setShowModal(false);
  }, []);
  const createUserElement = useCallback((user) => {
    return {
      id: user._id,
      email: user.email,
      username: user.username,
      label: (
        <div>
          <img className="guest-image" src={user.profileImageUrl} alt="" />
          <span> {user.username}</span>
          <span> {user.email}</span>
        </div>
      ),
    };
  }, []);

  const createUserElements = useCallback(
    (users) => {
      const userElements = users.map((user) => {
        return createUserElement(user);
      });
      return userElements;
    },
    [createUserElement]
  );
  const handleFormSubmit = async (values) => {
    try {
      // eslint-disable-next-line no-unused-vars
      const body = JSON.stringify(values);
      const responseData = await sendRequest(
        `${process.env.REACT_APP_BACKEND_URL}/sendEmail`,
        "PUT",
        body,
        { "Content-Type": "application/json" }
      );
      if (responseData) {
        setEmailSentAlert(true);
      }
    } catch (err) {
      console.log("ERROR Updating User : " + err.message);
    }
  };
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const users = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/users`,
          "GET",
          null,
          { Authorization: "Bearer " + auth.token }
        );

        setSelectedUserElements(createUserElements(users));
        setUserElements(createUserElements(users));
      } catch (err) {}
    };
    fetchUsers();
  }, [createUserElements, sendRequest, auth.userId, auth.token]);

  const AddUserElement = useCallback(
    (user) => {
      setUserElements([createUserElement(user), ...userElements]);
      setSelectedUserElements([
        createUserElement(user),
        ...selectedUserElements,
      ]);
    },
    [createUserElement, setUserElements, userElements, selectedUserElements]
  );

  const handleSelectedChanged = (selectedItems) => {
    setSelectedUserElements(selectedItems);
  };

  return (
    <React.Fragment>
      <AlertModal
        alert={emailSentAlert}
        header="Success"
        message="The email has been sent."
        onClear={() => history.goBack()}
      ></AlertModal>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div className="center">
          <LoadingSpinner />
        </div>
      )}
      {!showModal && (
        <div className={styles.recipientList}>
          <button type="button" className="btn" onClick={displayModal}>
            Edit Recipient List
          </button>
          <button type="button" className="btn" onClick={toggleUsername}>
            Show {showUsername === true ? "Email" : "Name"}
          </button>
        </div>
      )}

      {!isLoading && showModal && (
        <Modal
          className={styles["modalWrapper"]}
          onCancel={hideModal}
          header="Email Recipients"
          headerClass="modalNewList"
          show={showModal}
        >
          <div className={styles.editButtons}>
            <AddEmailAddressModalForm addSlectedUserElement={AddUserElement} />
          </div>

          <MultipleSelect
            wrapperClassName={styles.multiSelectWrapper}
            items={userElements}
            selectedItems={selectedUserElements}
            onChange={handleSelectedChanged}
            showSearch={false}
            height={"75vh"}
            responsiveHeight="50%"
          />
          <div className={styles.centerChild}>
            <button type="button" className="btn" onClick={hideModal}>
              Close
            </button>
          </div>
        </Modal>
      )}

      <Formik
        enableReinitialize
        initialValues={{
          emailAddress: selectedUserElements
            .map((u) => (showUsername ? u.username : u.email))
            .join("; "),
          subject: `Corona Website message from ${auth.username}`,
          message: "",
        }}
        onSubmit={handleFormSubmit}
        validationSchema={yup.object().shape({
          message: yup.string().required("Message is required."),
          subject: yup.string().required("Subject is required."),
          emailAddress: yup.string().required("Email Address is required."),
        })}
      >
        {({ errors, touched, isValid, dirty, values, ...formik }) => (
          <div>
            <Form className={styles.wrapper}>
              <div className={styles.displayEmails}>
                {selectedUserElements
                  .map((u) => (showUsername ? u.username : u.email))
                  .join("; ")}
              </div>

              <MyTextInput
                className={styles.emailControl}
                label="Subject"
                name="subject"
                type="text"
                placeholder="Enter Subject"
              />

              <MyTextArea
                className={styles.emailControl}
                label="Message"
                name="message"
                rows="10"
                placeholder="Enter message details"
              />

              <div className={`listItemEditControls ${styles.listAdd}`}>
                <button
                  className="btn"
                  aria-label="Add"
                  type="submit"
                  disabled={!(dirty && isValid)}
                >
                  Send
                </button>
                <button
                  className="btn"
                  aria-label="Cancel"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default Email;
