import React from "react";

const UsernameList = (props) => {
  return (
    <span>
      {props.users &&
        props.users.map(function (user, idx) {
          return (
            <span className="m-3" key={user._id}>
              {user.username}
            </span>
          );
        })}
    </span>
  );
};

export default UsernameList;
