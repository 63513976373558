import React from 'react'
import ReactDatePicker from "react-datepicker";

const DatePicker = (props) => {
    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const { field, form } = props;
    const meta = form.getFieldMeta(field.name);
    
    const handleDateChange = (v) => {
      form.setFieldValue(field.name, v);
      form.setFieldTouched(field.name, true, false);
      form.validateField(field.name);
    }
    
    return (
  <>
      <div className={`form-control ${meta.touched && meta.error && 'form-control--invalid'}`}  >
        <label htmlFor={props.id || props.name}>{props.label}</label>
        <ReactDatePicker
        name={field.name}
        onChange={ handleDateChange }
        selected={field.value}
        />
      </div>
       {meta.touched && meta.error ? (
        <div>{meta.error}</div>
      ) : null}
   </>
    );
  };

    export default  DatePicker;