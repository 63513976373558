import React from "react";

const TagList = (props) => {
  return (
    <span>
      {props.tags &&
        props.tags.map(function (tag, idx) {
          return (
            <span className="m-3" key={idx}>
              {tag}
            </span>
          );
        })}
    </span>
  );
};

export default TagList;
