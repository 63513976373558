import React from "react";
import "./ActivityLogItem.css";
import moment from "moment";
import { Link } from "react-router-dom";
import Tooltip from "../../utils/Tooltip";

const ActivityLogItem = (props) => {
  const dateOfDay = moment(props.activityLog.createdAt).format(
    "MMMM Do YYYY, h:mm:ss a"
  );
  const dateOfDeparture = moment(props.activityLog.trip.departureDate).format(
    "MMMM Do YYYY"
  );

  if (props.activityLog.trip.history)
    return (
      <li className="activityLog-item">
        <Link to="/memories">
          <Tooltip
            message="Click on this line to navagate to the Vintage Photos."
            position="right"
          >
            <p>
            <span className="activityLog-item__label">{`${props.activityLog.text} by ${props.activityLog.username} on ${dateOfDay} `}</span>
            <span className="activityLog-item__label">Vintage Photos</span>
            </p>
          </Tooltip>
        </Link>
      </li>
    );

  return (
    <li className="activityLog-item">
      <Link
        to={{
          pathname: `/users/${props.loggedInUser}/${props.activityLog.trip._id}/details`,
          state: { tripId: props.activityLog.trip._id },
        }}
      >
        <Tooltip
          message="Click on this line to navagate to the trip details."
          position="right"
        >
          <p>
            <span className="activityLog-item__label">{`${props.activityLog.text} by ${props.activityLog.username} on ${dateOfDay} `}</span>
            <span className="activityLog-item__label">
              Trip: {props.activityLog.trip.description}{" "}
            </span>
            <span className="activityLog-item__label">
              Departure: {dateOfDeparture}{" "}
            </span>
          </p>
        </Tooltip>
      </Link>
    </li>
  );
};

export default ActivityLogItem;
