import React, { useState } from 'react';

// styles
import styles from './Item.module.css';
import { Fragment } from 'react';

// Library imports


const Item = ({ listType, item, deleteItem, toggleItemCompleted, enterEditItemMode }) => {
  const [isChecked, setIsChecked] = useState(item.completed);

  const handleCheckboxChange = (e) => {
    setIsChecked(!isChecked);
    toggleItemCompleted(item._id);
  }

  const getCompletedStyle = () => {
    return isChecked && styles.lineThrough;
  }

  return (
    <Fragment>
      <div className={styles["itemGroup"]}>
        <input
          type="checkbox"
          className={styles.checkbox}
          checked={isChecked}
          onClick={handleCheckboxChange}
          name={item.name}
          id={item._id}
        />
        <label
          htmlFor={item._id}
          className={`${styles.label}`}
        >
          {item.name}

          <p className={styles.checkmark}>
            <i className="fas fa-solid fa-check"></i>
          </p>
        </label>
      </div>
      {listType !== "ToDo" && <div className={`${styles.itemGroup} ${getCompletedStyle()}`}>{item.quantity}</div>}

      <div className={`${styles.itemGroup} ${getCompletedStyle()}`}>
        {item.note}
      </div>

      <div className={styles["itemGroup"]}>
        <button
          className='btn'
          aria-label={`Update ${item.name} Task`}
          onClick={() => enterEditItemMode(item)}
        >
          <i className="fas fa-solid fa-edit"></i>
        </button>
      </div>

      <div className={styles["itemGroup"]}>
        <button
          className='btn'
          aria-label={`Delete ${item.name} Task`}
          onClick={() => deleteItem(item._id)}
        >
          <i className="fas fa-solid fa-trash"></i>
        </button>

      </div>
    </Fragment >
  )
}
export default Item