import React, { useEffect, useState, useContext } from "react";

import ListCollection from "../components/ListCollection";
import ErrorModal from "shared/components/UIElements/ErrorModal";
import LoadingSpinner from "shared/components/UIElements/LoadingSpinner";
import { useHttpClient } from "shared/hooks/http-hook";
import { AuthContext } from "shared/context/auth-context";
import ListWrapper from "campNotes/components/ListWrapper";

const ReminderLists = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [toDoLists, setToDoLists] = useState([]);
  const [shoppingLists, setShoppingLists] = useState([]);

  // using a date to trigger when the data was updated so it can be fetched. Not using the actual date but maybe someday...
  const [listUpdatedDate, setListUpdatedDate] = useState(null);
  const auth = useContext(AuthContext);
  const [list2Edit, setList2Edit] = useState(null);

  const populateReminderLists = (lists) => {
    const toDoList = [];
    const shoppingList = [];
    lists.forEach((list) => {
      if (list.listType === "ToDo") toDoList.push(list);
      else shoppingList.push(list);
    });
    setToDoLists(toDoList);
    setShoppingLists(shoppingList);
  };

  useEffect(() => {
    const fetchReminderLists = async () => {
      try {
        console.log("useEffect fetch ReminderLists");
        const lists = await sendRequest(
          `${process.env.REACT_APP_BACKEND_URL}/user/${auth.userId}/list`,
          "GET",
          null,
          {
            Authorization: "Bearer " + auth.token,
          }
        );
        populateReminderLists(lists);
      } catch (err) {
        console.log(err);
      }
    };

    fetchReminderLists();
  }, [auth.token, auth.userId, sendRequest, listUpdatedDate]);

  const listUpdated = () => {
    console.log("listUpdated");
    setListUpdatedDate(new Date());
  };
  console.log("Render ReminderLists ");
  console.log("list2Edit: " + list2Edit);

  if (list2Edit)
    // edit an individual list
    return (
      <ListWrapper
        key={list2Edit._id}
        list={list2Edit}
        listUpdated={listUpdated}
        setList2Edit={setList2Edit}
      />
    );

  return (
    // working with the collection of lists
    <React.Fragment>
      <ErrorModal error={error} onClear={clearError} />{" "}
      {isLoading && <LoadingSpinner asOverlay />}
      {!isLoading && (
        <ListCollection
          toDoLists={toDoLists}
          shoppingLists={shoppingLists}
          listUpdated={listUpdated}
          setList2Edit={setList2Edit}
        />
      )}
    </React.Fragment>
  );
};

export default ReminderLists;
