import React from "react";

import ActivityLogItem from "./ActivityLogItem";
import "./ActivityLogList.css";

const ActivityLogList = (props) => {
  if (!props.items || props.items.length === 0)
    return (
      <p className="text-center">
        No activities found for that search criteria!
      </p>
    );

  return (
    <ul className="activityLogs-list">
      {props.items &&
        props.items.map((activityLog) => (
          <ActivityLogItem
            loggedInUser={props.loggedInUser}
            key={activityLog._id}
            activityLog={activityLog}
          />
        ))}
    </ul>
  );
};

export default ActivityLogList;
